import React, { useRef, useState } from "react";
import TakeVideo from "../../takeVideo";

const VideoPicker = ({ setMediaToUpload, mediaError, mediaUploadBtnStyle }) => {
    // State to Store video for new library
    const [displayVidRecorder, setDisplayVidRecorder] = useState(true);
    // const [chooseVideoBtn, setChooseVideoBtn] = useState(false);

    //Show Video File Preview
    const [videoFile, setVideoFile] = useState();
    const [videoStyle, setVideoStyle] = useState(false);
    const [videoSizeStyle, setVideoSizeStyle] = useState(false);
    const hiddenVideoRef = useRef();

    const HandleChooseVideo = (event) => {
        hiddenVideoRef.current.click();
    };

    const handleVideoFileClick = (e) => {
        e.target.value = null;
    };

    function handleVideoFileChange(e) {
        let fileSize = e.target.files[0].size;
        // 300MB = 1024 * 1024 * 300
        if (e.target.files.length !== 0) {
            if (fileSize < 314572800) {
                setVideoFile(URL.createObjectURL(e.target.files[0]));

                setVideoStyle(true);
                setMediaToUpload(e.target.files[0]);

                setVideoSizeStyle(false);
                setDisplayVidRecorder(false)
            } else {
                setVideoSizeStyle(true);
            }
        }
    }

    return (
        <>
            <div className="text-center">
                <small className="text-muted">Max Recording Time (3 minutes)</small>
            </div>
            <div className="mb-3">
                <div className="mediaBorder">
                    {displayVidRecorder && (
                        <TakeVideo setRecordedVideo={setMediaToUpload} />
                    )}
                </div>
                <video
                    src={videoFile}
                    controls={true}
                    width="100%"
                    height="100%"
                    className={videoStyle === true ? "d-block mb-3" : "d-none"}
                ></video>

                <div className="text-center mt-2 mb-2">
                    <div className="col-12 text-center my-2">
                        <span>&nbsp; OR &nbsp;</span>
                    </div>

                    <button
                        type="button"
                        id="choose-video-btn"
                        // disabled={chooseVideoBtn}
                        className="btn btn-outline-primary upload-btn-mobile col-12"
                        onClick={HandleChooseVideo}
                        style={mediaUploadBtnStyle}
                    >
                        Choose Video
                    </button>
                </div>
            </div>

            <input
                type="file"
                name="file"
                accept="video/*"
                className="form-control set-input-dark d-none"
                id="ut-video-upload"
                // {...register('revAsset', {
                //     required: {
                //         value: editModeActivated ? (reviewToEdit.revAsset === null) : true,
                //         message: "Please upload a video"
                //     }
                // })}
                onChange={handleVideoFileChange}
                onClick={handleVideoFileClick}
                ref={hiddenVideoRef}
            />
            <span className={videoSizeStyle ? "text-danger d-block" : "d-none"}>
                Video File cannot be more than 300MB
            </span>
            {mediaError && (
                <span className="text-danger">
                    {mediaError}
                </span>
            )}
        </>
    )
}
export default VideoPicker