import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ThankYouPage = () => {
    const location = useLocation();
    const path = location.pathname.split("/");
    var formID = null;
    var brandURL = null;
    var url = null;
    if (path.length === 5) {
        formID = path[path.length - 2]
        brandURL = path[path.length - 3]
        url = `https://w39wh0twqf.execute-api.ap-southeast-1.amazonaws.com/default/anrv2customreviewget-dev/c/thankyou/${brandURL}/${formID}`
    }

    // const pageContent = location.state?.pageContent;
    // const pageStyle = location.state?.pageStyle

    const pageContent = {
        "Title": "Singapore",
        "redirectURL": "https://www.google.com/",
        "Message": "Singapore is beautiful",
        "btnTxt": "Lets go"
    }

    const pageStyle = {
        "reviewPage": {
            "btnBgColor": "#0d6efd",
            "btnTxtColor": "#ffffff",
            "buttonRadius": "5",
            "cardBorderColor": "#000000",
            "cardBgColor": "#ffffff",
            "cardRadius": "5",
            "cardShadow": "normal",
            "contentBgColor": "#000000",
            "textColor": "#000000"
        },
        "writeReviewPage": {
            "formBgColor": "#000000",
            "formTxtColor": "#ffffff",
            "mediaBgColor": "#ffffff",
            "mediaBtnRadius": "5",
            "mediaTxtColor": "#0d6efd",
            "submitBgColor": "#0d6efd",
            "submitBtnRadius": "5",
            "submitTxtColor": "#ffffff"
        },
        "thankYouPage": {

            "tyBgColor": "#023047",
            "tyBorderColor": "#023047",
            "tyCardRadius": "0.5rem",
            "tyCardTxtColor": "#ffffff",
            "tyCTABgColor": "#FB8500",
            "tyCTARadius": "0.25rem",
            "tyCTATxtColor": "#ffffff",
            "tyCardShadow": "5px 5px 18px 0px black"
        }
    }

    const [pageContent2, setPageContent] = useState(pageContent);
    const [pageStyle2, setPageStyle] = useState(pageStyle);

    const [isLoadingData, setIsLoadingData] = useState(true);

    // fetch thankYou details
    useEffect(() => {
        async function getThankYouDetails() {
            await axios.get(url, null, {})
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.success.formData) {
                            const formData = JSON.parse(response.data.success.formData)
                            setPageContent(formData?.thankYou)
                            setPageStyle(formData?.design)
                        }
                    }
                    setIsLoadingData(false)
                }).catch((error) => {
                    console.log('Failed to fetch information')
                    setIsLoadingData(false)
                })
        }

        if (formID && brandURL) {
            getThankYouDetails()
        }
        // eslint-disable-next-line
    }, [])

    const redirectBtnStyle = {
        backgroundColor: pageStyle2?.tyCTABgColor,
        color: pageStyle2?.tyCTATxtColor,
        borderRadius: pageStyle2?.tyCTARadius ? pageStyle2.tyCTARadius + 'px' : ''
    }

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center h-100" style={{ backgroundColor: pageStyle2?.tyBgColor }}>
            {isLoadingData ? (<>Loading</>) : (
                <div className="row justify-content-center" style={{ width: 'inherit' }}>
                    <div className="card px-md-5 py-md-5 px-2 py-2 text-center w-50" style={{
                        backgroundColor: pageStyle2?.tyCardColor,
                        borderColor: pageStyle2?.tyBorderColor,
                        borderRadius: pageStyle2?.tyCardRadius ? pageStyle2.tyCardRadius + 'px' : '',
                        color: pageStyle2?.tyCardTxtColor,
                        boxShadow: pageStyle2?.tyCardShadow,
                    }}>
                        <h3>
                            {pageContent2?.Title}
                        </h3>
                        <span className="py-3">
                            {pageContent2?.Message}
                        </span>

                        <a className="btn" style={redirectBtnStyle}
                            href={pageContent2?.redirectURL}>
                            {pageContent2?.btnTxt}
                        </a>
                    </div>
                </div>
            )}


        </div>
    )
}

export default ThankYouPage

// example variables from database
// ThankYouPage.defaultProps = {
//     pageContent: {
//         "btnTxt": "Go",
//         "Message": "Thank You very much for helping us to write a review",
//         "redirectURL": "https://alicialim0123.wixsite.com/portfolio",
//         "Title": "Thank You",
//         "URL": ""
//     },
//     pageStyle: {
//         tyBgColor: "#FFFFFF",
//         tyBorderColor: '#000000',
//         tyCardRadius: 5,
//         tyCardShadow: "",
//         tyCardTxtColor: "#000000",
//         tyCTABgColor: '#0d6efd',
//         tyCTARadius: 5,
//         tyCTATxtColor: "#FFFFFF"
//     }
// }