import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";

import TextImageReviewForm from "../UI/textImageReviewForm";
import AudioReviewForm from "../UI/audioReviewForm";
import VideoReviewForm from "../UI/videoReviewModal";

import { ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { FadeIn } from "react-slide-fade-in";
import EndlessReviews from "../UI/endlessReviews";
import amplitude from "amplitude-js";

import NotfoundComponent from "../UI/notfoundComponent";

export default function Reviews() {
  const [showTextImageForm, setShowTextReviewForm] = useState(false);
  const [showAudioForm, setShowAudioForm] = useState(false);
  const [showVideoForm, setShowVideoForm] = useState(false);

  // Get current URL
  const location = useLocation();
  let navigate = useNavigate()
  // var path = location.pathname.split("/brand/")[1];
  var path = null
  var brandURL = null

  try {
    path = location.pathname.split("/brand/")[1];
    brandURL = path.split("/")[0];
  } catch (e) {
    // path = location.pathname.split("/brand/")[1];
    navigate("/pageNotFound")
  }

  // check if there is any other url
  // const paths = brandURL.split("/");

  // edit forms
  const [reviewToEdit, setReviewToEdit] = useState({});
  const [editModeActivated, setEditModeActivated] = useState(false);

  //Delete Forms
  // const [reloadReviewList, doReloadReviewList] = useState(false);

  // loading state when waiting for lambda to update count
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);
  const [stopSpinner, setStopSpinner] = useState(false);

  const [reviewList, SetReviewsList] = useState([]);
  const [brandList, SetBrandList] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  //Brand Info Color
  const [bndBgColor, SetBndBgColor] = useState("");
  const [bndThemeMode, SetBndThemeMode] = useState("");
  const [bndBgImage, SetBndBgImage] = useState("");
  const [pageDesign, setPageDesign] = useState({});

  // const url = `https://k8nrna30s5.execute-api.ap-southeast-1.amazonaws.com/default/anrv1reviewsget/c/${isInitialLoad ? '1/' : ''}` + brandURL + "/widget1"
  const url =
    `https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/${isInitialLoad ? "1/" : ""
    }` +
    brandURL +
    "/widgetRevPage";
  const defaultHeaderBgImg = "a-nice-review-background-image.jpg";

  const fetchReviews = () => {
    var params = {};
    if (lastEvaluatedKey !== null) {
      params = lastEvaluatedKey;
    } else {
      params = {};
    }

    axios
      .post(url, null, {
        params: params,
      })
      .then((res) => {
        var newList = [];
        if (res.data.reviews) {
          newList = JSON.parse(res.data.reviews).filter((review) => !reviewList.map((rev) => rev.id).includes(review.id))
        }

        SetReviewsList([...reviewList, ...newList]);
        SetBrandList(JSON.parse(res.data.brandDetails).Items);

        let brand = JSON.parse(res.data.brandDetails).Items;

        if (brand[0]) {
          if (brand[0].revPageDesign) {
            const design = brand[0].revPageDesign;

            setPageDesign(design);

            if (
              design.useDefaultBgImg === true ||
              design.useDefaultBgImg === ""
            ) {
              // TODO: Change default image domain if domain changed
              let headerImageURL =
                "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://www.anicereview.co/" +
                defaultHeaderBgImg +
                "')";
              SetBndBgImage(headerImageURL);
            } else {
              if (design.headerBgImg) {
                let headerImageURL =
                  "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('" +
                  design.headerBgImg +
                  "')";
                SetBndBgImage(headerImageURL);
              }
            }
            if (design.headerBgColor) {
              SetBndBgColor(design.headerBgColor);
            }

            if (design.theme !== null) {
              SetBndThemeMode(design.theme);
            }
          }
        }

        // if (brand.length > 0) {
        //   // SetBndBgColor(brand[0].bndBgColor);
        //   SetBndThemeMode(brand[0].bndRevTheme);
        //   // SetBndBtnColor(brand[0].bndBtnColor);
        //   // SetBndTextColor(brand[0].bndTextColor);

        //   // let bgImageURL = "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('" + brand[0].bndBgImage + "')"
        //   // SetBndBgImage(bgImageURL);
        // }

        if (res.data.lastEvaluatedKey) {
          if (Object.keys(JSON.parse(res.data.lastEvaluatedKey)).length > 0) {
            setLastEvaluatedKey(JSON.parse(res.data.lastEvaluatedKey));
          } else {
            setLastEvaluatedKey(null);
          }
        }

        setTimeout(() => {
          setIsInitialLoad(false);
          setStopSpinner(true);
          setIsLoadingReviews(true);
        }, 50);
      })
      .catch(() => {
        console.log("Error loading reviews")
        setIsLoadingReviews(false);
      });
  };

  useEffect(() => {
    // initialize amplitude
    amplitude.getInstance().init("18e602013ebb427346ff0b80e92da0a3");
    amplitude.getInstance().logEvent(`${brandURL}::mainReviews`);
  }, [brandURL]);

  // const fetchAmplitudeEvents = async () => {
  //   await axios.get('', null, {

  //   })
  // }

  useEffect(() => {
    fetchReviews();

    ReactGA.send({ hitType: "pageview", page: location.pathname });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(pageDesign).length > 0) {
      if (document.getElementById("bg-nested-dropdown")) {
        if (pageDesign.btnTxtColor) {
          document.getElementById("bg-nested-dropdown").style.color =
            pageDesign.btnTxtColor;
        }
        if (pageDesign.btnBgColor) {
          document.getElementById("bg-nested-dropdown").style.backgroundColor =
            pageDesign.btnBgColor;
          document.getElementById("bg-nested-dropdown").style.borderColor =
            pageDesign.btnBgColor;
        }
      }

      if (pageDesign.contentBgColor) {
        document.body.style.backgroundColor = pageDesign.contentBgColor;
      }
    } else {
      // Brand not found
      document.body.style.backgroundColor = "#2b2b2b";
      // document.body.style.backgroundImage = "a-nice-review-background-image.jpg"
      // document.body.style.backgroundSize = "cover"
    }
  }, [stopSpinner, pageDesign]);

  // Set Dark / Light Mode
  // if (bndThemeMode === "on") {
  //   document.body.classList.add('dark-theme-body')
  // } else {
  //   document.body.classList.remove('dark-theme-body')
  // }

  return (
    <>
      <TextImageReviewForm
        showModal={showTextImageForm}
        handleClose={() => {
          setShowTextReviewForm(false);
          setReviewToEdit({});
          setEditModeActivated(false);
        }}
        title="Text/Photo Review"
        setIsUpdatingData={setIsUpdatingData}
        isUpdatingData={isUpdatingData}
        reviewToEdit={reviewToEdit}
        editModeActivated={editModeActivated}
        brandURL={brandURL}
      />

      <AudioReviewForm
        showModal={showAudioForm}
        handleClose={() => {
          setShowAudioForm(false);
          setReviewToEdit({});
          setEditModeActivated(false);
        }}
        title="Audio Review"
        isUpdatingData={isUpdatingData}
        setIsUpdatingData={setIsUpdatingData}
        editModeActivated={editModeActivated}
        reviewToEdit={reviewToEdit}
        brandURL={brandURL}
      />

      <VideoReviewForm
        showModal={showVideoForm}
        handleClose={() => {
          setShowVideoForm(false);
          setReviewToEdit({});
          setEditModeActivated(false);
        }}
        title="Video Review"
        isUpdatingData={isUpdatingData}
        setIsUpdatingData={setIsUpdatingData}
        editModeActivated={editModeActivated}
        reviewToEdit={reviewToEdit}
        brandURL={brandURL}
      />

      {stopSpinner === false ? (
        <div className="p-5 text-center">
          <Spinner
            animation="border"
            variant={bndThemeMode === "dark" ? "light" : "dark"}
          />
        </div>
      ) : (
        <>
          {brandList.length !== 0 ? (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>
                  Online customer reviews and testimonials of{" "}
                  {brandList[0].bndName}
                </title>
                <meta
                  name="description"
                  content={
                    "Find out why customers love " + brandList[0].bndName
                  }
                />
                <meta property="og:site_name" content="A Nice Review" />
                <meta
                  property="og:title"
                  content={
                    "Online customer reviews and testimonials of " +
                    brandList[0].bndName
                  }
                />
                <meta
                  property="og:description"
                  content={
                    "Find out why customers love " + brandList[0].bndName
                  }
                />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="@adrianteoyc" />
                <meta
                  name="twitter:title"
                  content={
                    "Online customer reviews and testimonials of " +
                    brandList[0].bndName
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    "Find out why customers love " + brandList[0].bndName
                  }
                />
                <meta name="twitter:image" content="" />
              </Helmet>
              <ToastContainer />
              <FadeIn
                from="bottom"
                positionOffset={0}
                triggerOffset={0}
                delayInMilliseconds={50}
              >
                <div
                  className="hero-image"
                  style={{
                    backgroundColor: bndBgColor,
                    backgroundImage: bndBgImage,
                  }}
                >
                  <div className="hero-text">
                    <div
                      style={
                        pageDesign.bndNameTxtColor
                          ? { color: pageDesign.bndNameTxtColor }
                          : {}
                      }
                    >
                      {brandList[0].bndName}
                    </div>

                    <div className="mt-2 mb-4">
                      {brandList[0].bndLogo && (
                        <img
                          src={brandList[0].bndLogo}
                          alt={brandList[0].bndTitle}
                          style={{ height: "70px" }}
                        />
                      )}
                    </div>

                    <div
                      className="title-header mb-2"
                      style={{
                        color: pageDesign.bndTitleColor
                          ? pageDesign.bndTitleColor
                          : "",
                      }}
                    >
                      {pageDesign?.content?.title
                        ? pageDesign.content.title
                        : "Our Reviews"}
                    </div>

                    {pageDesign?.content?.message && (
                      <div
                        className="text-center mb-4"
                        style={{
                          color: pageDesign.bndMsgColor
                            ? pageDesign.bndMsgColor
                            : "",
                        }}
                      >
                        {pageDesign.content.message}
                      </div>
                    )}
                    <div className="ms-auto p-1" id="review-dropdown-container">
                      <DropdownButton
                        as={ButtonGroup}
                        className={`title-header-button`}
                        title="Share A Nice Review"
                        id="bg-nested-dropdown"
                        size="lg"
                      >
                        {pageDesign?.content?.bndOptions && pageDesign.content.bndOptions.includes("image") && (
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => setShowTextReviewForm(true)}
                          >
                            Text/Photo Review
                          </Dropdown.Item>
                        )}

                        {pageDesign?.content?.bndOptions && pageDesign.content.bndOptions.includes("audio") && (
                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => setShowAudioForm(true)}
                          >
                            Audio Review
                          </Dropdown.Item>
                        )}

                        {pageDesign?.content?.bndOptions && pageDesign.content.bndOptions.includes("video") && (
                          <Dropdown.Item
                            eventKey="3"
                            onClick={() => setShowVideoForm(true)}
                          >
                            Video Review
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </div>
                    {pageDesign?.content?.hideBranding !== true && (
                      <div className="ms-auto">
                        <a
                          href="https://www.anicereview.com"
                          target="_blank"
                          rel="noreferrer"
                          className="title-header-link"
                        >
                          Collect And Share Reviews For Free 🤝
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </FadeIn>

              <FadeIn
                from="bottom"
                positionOffset={0}
                triggerOffset={0}
                delayInMilliseconds={50}
              >
                {/* TODO Background Color Of Reviews  */}
                <div
                  className={
                    "main-review-page-padding " +
                    (bndThemeMode === "dark"
                      ? "dark-theme-body"
                      : "white-theme-body")
                  }
                >
                  <div className="container px-3">
                    {!isLoadingReviews ? (
                      <div className="set-text-white">Loading Reviews...</div>
                    ) : reviewList.length > 0 ? (
                      // <XMasonry maxColumns={4} center={false}>
                      //   {reviewList.map((review, index) => (
                      //     <XBlock key={index}>
                      //       <div className="col-lg-12 mb-3 dark-theme-card">
                      //         <div className="card">
                      //           <div className="d-flex justify-content-between p-2 pt-3 px-3 dark-inner-card inner-card-top">
                      //             <div className="d-flex flex-row align-items-center">
                      //               {(review.revProfileImg === "" || review.revProfileImg === null) ?
                      //                 <div className='profile-default-Image pt-2 pb-2'><p>{review.revName.charAt(0)}</p></div> :
                      //                 <img src={review.revProfileImg} width="50px" height="50px" alt="" className="rounded-circle" />
                      //               }
                      //               <div className="d-flex flex-column ms-2 review-header-column">
                      //                 <span className="font-weight-bold lh-sm review-name set-text-white">{review.revName}</span>
                      //                 <small className="text-primary lh-sm review-name set-text-light-white">{review.revTitleCom}</small>
                      //                 <small className="text-muted lh-sm set-text-light-white">{new Date(review.createdAt).toLocaleDateString('en-UK', { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                      //               </div>
                      //             </div>
                      //             <div className="d-flex flex-row align-self-center">
                      //               {review.revSocialProvider === "Twitter" && (
                      //                 <i className="bi bi-twitter"></i>
                      //               )}
                      //               {review.revSocialProvider === "Google Map" && (
                      //                 <i className="bi bi-map"></i>
                      //               )}
                      //             </div>
                      //           </div>

                      //           {review.revType === "text" ? (
                      //             <div className='pt-2 pb-2 dark-inner-card'>
                      //               {(review.revContent !== "P undefined" && review.revContent !== "P ") &&
                      //                 <div className="pt-2 pb-2 px-3 dark-inner-card inner-card-bottom">
                      //                   <div className="lh-sm text-justify set-text-white">{review.revContent}</div>
                      //                 </div>
                      //               }
                      //             </div>)
                      //             :
                      //             (<div className='pt-2 pb-2 dark-inner-card'>
                      //               {(review.revContent !== "P undefined" && review.revContent !== "P ") &&
                      //                 <div className="pt-2 pb-2 px-3 dark-inner-card">
                      //                   <div className="lh-sm text-justify set-text-white">{review.revContent}</div>
                      //                 </div>
                      //               }
                      //             </div>
                      //             )}

                      //           {review.revType === "image" && (
                      //             review.revAsset && <img src={review.revAsset}
                      //               className={review.revAsset.includes("photo_taking.png") ? "img-fluid inner-card-bottom preview-image-rotate" : "img-fluid inner-card-bottom"}
                      //               alt="" />
                      //           )}

                      //           {review.revType === "video" && (
                      //             // Check if the video is recorded and is not taken from gallery so as to mirror the video
                      //             <video src={review.revAsset} controls={true} width="100%" height="100%"
                      //               className={review.revAsset.includes("demo.mp4") ? "set-video-dark inner-card-bottom preview-video-rotate" : "set-video-dark inner-card-bottom"}
                      //             ></video>
                      //           )}

                      //           {review.revType === "audio" && (
                      //             <audio src={review.revAsset} controls style={{ width: '100%' }} className="review-audio-container inner-card-bottom" />
                      //           )}

                      //         </div>
                      //       </div>
                      //     </XBlock>
                      //   ))}
                      // </XMasonry>
                      <EndlessReviews
                        brandURL={brandURL}
                        stopSpinner={stopSpinner}
                        fetchReviews={fetchReviews}
                        lastEvaluatedKey={lastEvaluatedKey}
                        reviewList={reviewList}
                        bndThemeMode={bndThemeMode}
                      />
                    ) : (
                      <div className="text-center h4 mt-3 mb-5 set-text-white">
                        End of Reviews
                      </div>
                    )}
                  </div>
                </div>
              </FadeIn>
            </>
          ) : (
            <FadeIn
              from="bottom"
              positionOffset={0}
              triggerOffset={0}
              delayInMilliseconds={50}
            >
              <NotfoundComponent brandURL={brandURL} />
            </FadeIn>
          )}
        </>
      )}
    </>
  );
}
