import React from 'react';
import ReactDOM from 'react-dom';
import "./custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/js/bootstrap.min.js"
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const google_recaptcha_secret_key = "6LeKHSQhAAAAAHmi66Skezu8RqODHf0OPnDdqa95"
ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={google_recaptcha_secret_key}>
      <App />
    </GoogleReCaptchaProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
