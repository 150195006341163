import { BrowserRouter, Routes, Route } from "react-router-dom";
import Reviews from "./urls/reviews";
import Testapi from "./urls/testapi";
import WriteReviewPage from "./urls/writeReviewPage";
import Widget1 from "./urls/widget1";
import Widget2 from "./urls/widget2";
import Widget3 from "./urls/widget3";
import Widget2a from "./urls/widget2a";

import "./custom.scss";

import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import PageNotFound from "./UI/pageNotFound";
import CustomFormReview from "./urls/customFormReview";
import ThankYouPage from "./UI/custom-forms/thankYouPage";
import CustomWidgetPage from "./urls/customWidgetPage";

const react_GA_key = "G-N5GCX4KWS4";
ReactGA.initialize(react_GA_key);

function Redirect() {
  // 👇️ redirect to external URL
  window.location.replace("https://www.anicereview.com");

  return null;
}

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Redirect />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/brand/*" element={<Reviews />} />
          <Route path="/brand/:id/widget1" element={<Widget1 />} />
          <Route path="/brand/:id/widget2" element={<Widget2 />} />
          <Route path="/brand/:id/widget2a" element={<Widget2a />} />
          <Route path="/brand/:id/widget3" element={<Widget3 />} />
          <Route path="/brand/:id/widget-:widgetID" element={<CustomWidgetPage />} />

          <Route path="/brand/:id/write-review" element={<WriteReviewPage />} />
          {/* <Route path="/brand/:id/write-review/:formID" element={<WriteReview2 />} /> */}
          <Route path="/brand/:id/write-review/:formID" element={<CustomFormReview />} />
          <Route path="/brand/:id/:formID/thankyou" element={<ThankYouPage />} />
          <Route path="/testapi/*" element={<Testapi />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
