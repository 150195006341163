import { React, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import { XMasonry, XBlock } from 'react-xmasonry';

import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroll-component"

// import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import EndlessReviews from "../UI/endlessReviews";
import amplitude from "amplitude-js";

function Widget1() {
  const [stopSpinner, setStopSpinner] = useState(false);

  const location = useLocation();
  const brandURL = location.pathname.split("/")[2];
  // const widgetType = location.pathname.split("/")[-1

  useEffect(() => {
    // initialize amplitude
    amplitude.getInstance().init("18e602013ebb427346ff0b80e92da0a3");
    amplitude.getInstance().logEvent(`${brandURL}::widget1`);
  }, [brandURL]);

  const [reviewList, SetReviewsList] = useState([]);
  const [brandList, SetBrandList] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // const url = "https://64kbo1ldn2.execute-api.ap-southeast-1.amazonaws.com/default/v1customerReviewGET/c/" + brandURL + "/" + widgetType
  // const url = `https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/${isInitialLoad ? '1/' : ''}` + brandURL + "/widget1"
  const url =
    `https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/${isInitialLoad ? "1/" : ""
    }` +
    brandURL +
    "/widget1";

  const fetchReviews = () => {
    var params = {};
    if (lastEvaluatedKey !== null) {
      params = lastEvaluatedKey;
    } else {
      params = {};
    }

    axios
      .post(url, null, {
        params: params,
      })
      .then((res) => {
        var newList = [];
        if (res.data.reviews) {
          JSON.parse(res.data.reviews).map((review) => {
            if (!reviewList.map((rev) => rev.id).includes(review.id)) {
              newList.push(review);
            }
            return "";
          });
        }

        SetReviewsList([...reviewList, ...newList]);

        if (JSON.parse(res.data.brandDetails).Items.length > 0) {
          SetBrandList(JSON.parse(res.data.brandDetails).Items[0]);
        } else {
          SetBrandList({});
        }

        if (res.data.lastEvaluatedKey) {
          if (Object.keys(JSON.parse(res.data.lastEvaluatedKey)).length > 0) {
            setLastEvaluatedKey(JSON.parse(res.data.lastEvaluatedKey));
          } else {
            setLastEvaluatedKey(null);
          }
        } else {
          setLastEvaluatedKey(null);
        }

        setIsInitialLoad(false);

        setTimeout(() => {
          setStopSpinner(true);
        }, 50);
      })
      .catch((err) => {
        // console.log(err);
        console.log("Error loading data")
      });
  };

  useEffect(() => {
    fetchReviews();

    // ReactGA.send({ hitType: "pageview", page: location.pathname });
    //eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        backgroundColor: brandList?.widget1Design?.bgColor
          ? brandList.widget1Design.bgColor
          : "",
      }}
    >
      {stopSpinner === false ? (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="dark" />
        </div>
      ) : (
        <div>
          {Object.keys(brandList).length !== 0 ? (
            <div className="pt-5 pb-5">
              <Helmet>
                <meta charSet="utf-8" />
                <title>
                  Online customer endorsement and feedback of{" "}
                  {brandList.bndName}
                </title>
                <meta
                  name="description"
                  content={"Find out why customers love " + brandList.bndName}
                />
                <meta property="og:site_name" content="A Nice Review" />
                <meta
                  property="og:title"
                  content={
                    "Online customer endorsement and feedback of " +
                    brandList.bndName
                  }
                />
                <meta
                  property="og:description"
                  content={"Find out why customers love " + brandList.bndName}
                />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="@adrianteoyc" />
                <meta
                  name="twitter:title"
                  content={
                    "Online customer endorsement and feedback of " +
                    brandList.bndName
                  }
                />
                <meta
                  name="twitter:description"
                  content={"Find out why customers love " + brandList.bndName}
                />
                <meta name="twitter:image" content="" />
              </Helmet>
              <div className="container px-3">
                <EndlessReviews
                  brandURL={brandURL}
                  stopSpinner={stopSpinner}
                  fetchReviews={fetchReviews}
                  lastEvaluatedKey={lastEvaluatedKey}
                  reviewList={reviewList ? reviewList : []}
                  hideEndRevMsg={true}
                  bndThemeMode={brandList.widget1Design.theme}
                />
                {/* {reviewList.length > 0 ? (
                  <InfiniteScroll
                    dataLength={reviewList.length}
                    hasMore={stopSpinner && lastEvaluatedKey !== null}
                    next={fetchReviews}
                    loader={<h5>Loading more data...</h5>}
                    endMessage={
                      <div className='text-center'>
                        <h5>No more items</h5>
                      </div>

                    }>
                    <XMasonry maxColumns={4} center={false}>
                      {reviewList.map((review, index) => (
                        <XBlock key={index}>
                          <div className="col-lg-12 mb-3">
                            <div className="card dark-theme-card">
                              <div className="d-flex justify-content-between p-2 px-3 dark-inner-card">
                                <div className="d-flex flex-row align-items-center">
                                  {(review.revProfileImg === "" || review.revProfileImg === null) ?
                                    <div className='profile-default-Image pt-2 pb-2'><p>{review.revName.charAt(0)}</p></div> :
                                    <img src={review.revProfileImg} width="50px" height="50px" alt="" className="rounded-circle" />
                                  }
                                  <div className="d-flex flex-column ms-2 review-header-column">
                                    <span className="font-weight-bold lh-sm review-name set-text-white">{review.revName}</span>
                                    <small className="text-primary lh-sm review-name set-text-light-white">{review.revTitleCom}</small>
                                    <small className="text-muted lh-sm set-text-light-white">{new Date(review.createdAt).toLocaleDateString('en-UK', { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-self-center">
                                  {review.revSocialProvider === "Twitter" && (
                                    <i className="bi bi-twitter"></i>
                                  )}
                                  {review.revSocialProvider === "Google Map" && (
                                    <i className="bi bi-map"></i>
                                  )}
                                </div>
                              </div>

                              {(review.revContent !== "P undefined" && review.revContent !== "P ") &&
                                <div className="pt-2 pb-2 px-3 dark-inner-card">
                                  <div className="lh-sm text-justify set-text-white">{review.revContent}</div>
                                </div>
                              }

                              {review.revType === "image" && (
                                review.revAsset && <img src={review.revAsset}
                                  className={review.revAsset.includes("photo_taking.png") ? "img-fluid preview-image-rotate" : "img-fluid"}
                                  alt="" />
                              )}

                              {review.revType === "video" && (
                                // <ReactPlayer url={review.revAsset} controls={true} width="100%" height="200px" className="set-video-dark"/>
                                <video src={review.revAsset} controls={true} width="100%" height="100%"
                                  className={review.revAsset.includes("demo.mp4") ? "set-video-dark preview-video-rotate" : "set-video-dark"}></video>
                              )}

                              {review.revType === "audio" && (
                                <audio src={review.revAsset} controls style={{ width: '100%' }} className="review-audio-container" />
                              )}

                            </div>
                          </div>
                        </XBlock>
                      ))}
                    </XMasonry>
                  </InfiniteScroll>
                ) : (<div className='set-text-white'>Reviews Are Not Available</div>)} */}
              </div>
            </div>
          ) : (
            <div className="text-center set-text-white">No Brand Found</div>
          )}
        </div>
      )}

      {brandList?.widget1Design?.hideBranding !== true && (
        <div className="ms-auto p-1 text-center">
          <Link
            to="https://www.anicereview.com"
            target="_blank"
            className="title-header-link text-dark"
          >
            Powered By A Nice Review 💪
          </Link>
        </div>
      )}
    </div>
  );
}

export default Widget1;
