import React from "react";
import VideoRecorder from "react-video-recorder";

const TakeVideo = ({ setRecordedVideo }) => {
    return (
        <>
            <VideoRecorder onRecordingComplete={(videoBlob) => {
                // convert blob to file
                const convertedVideo = new File(
                    [videoBlob],
                    "demo.mp4",
                    { type: "video/mp4" }
                );

                setRecordedVideo(convertedVideo)

            }} timeLimit={180000}/>
        </>
    )
}

export default TakeVideo