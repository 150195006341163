import React, { useRef, useState } from "react";
import TakePhotoV2 from "../../takePhotoV2";

const ImagePicker = ({ setMediaToUpload, mediaError, mediaUploadBtnStyle }) => {
    //Camera
    const cameraRef = useRef(null)
    const hiddenImageRef = useRef();
    const [showNewCamera, setShowNewCamera] = useState(true);

    // show image file preview
    const [imageFile, setImageFile] = useState();
    const [imageStyle, setImageStyle] = useState(false);
    const [imageSizeStyle, setImageSizeStyle] = useState(false);

    const HandleChoosePhoto = () => {
        hiddenImageRef.current.click();
    };

    function handleImageFileChange(e) {
        let fileSize = e.target.files[0].size;
        // 50MB = 1024 * 1024 * 50
        if (e.target.files.length !== 0) {
            if (fileSize < 52428800) {
                setShowNewCamera(false);
                cameraRef.current = null;
                setImageFile(URL.createObjectURL(e.target.files[0]));

                setImageStyle(true);
                setMediaToUpload(e.target.files[0])

                setImageSizeStyle(false);
            } else {
                setImageSizeStyle(true);
            }
        }
    }

    return (
        <>
            <div className="col-12 mb-3 justify-content-center" style={{ height: 'auto' }}>
                {/* Preview Image File */}
                {imageFile && !showNewCamera && (
                    <img
                        src={imageFile}
                        id="image-file-Element"
                        width="100%"
                        height="100%"
                        alt=""
                        className={imageStyle ? "d-block" : "d-none"}
                    />
                )}
                <div className="mediaBorder">
                    {showNewCamera && (

                        <TakePhotoV2
                            setPhotoFileArray={setMediaToUpload}
                            cameraRef={cameraRef}
                        />
                    )}
                </div>

                <div className="col-12 text-center mt-2">
                    <span>&nbsp; OR &nbsp;</span>
                </div>

                <div className="col-12 text-center mt-2 mb-2">
                    <button
                        type="button"
                        id="upload-photo-btn"
                        style={mediaUploadBtnStyle}
                        className="btn btn-outline-primary upload-btn-mobile col-12"
                        onClick={HandleChoosePhoto}
                    >
                        Choose Photo
                    </button>
                </div>

                <input
                    type="file"
                    name="image"
                    accept="image/*"
                    id="file-upload-image"
                    className="form-control set-input-dark d-none"
                    // {...register("revAsset", {})}
                    onChange={handleImageFileChange}
                    ref={hiddenImageRef}
                />
                <span
                    className={imageSizeStyle ? "text-danger d-block" : "d-none"}
                >
                    Image File cannot be more than 50MB
                </span>
                {mediaError && (
                    <span className="text-danger">
                        {mediaError}
                    </span>
                )}
            </div>
        </>
    )
}

export default ImagePicker