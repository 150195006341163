import React, { useEffect, useRef, useState } from "react";
import TakeAudioV2 from "../../takeAudioV2";

const AudioPicker = ({ setMediaToUpload, mediaError, mediaUploadBtnStyle }) => {
    // Record Audio
    const hiddenAudioRef = useRef();
    // const [chooseAudioBtn, setChooseAudioBtn] = useState(false);

    //Show Audio File Preview
    const [audioFile, setAudioFile] = useState();
    const [audioStyle, setAudioStyle] = useState(false);
    const [audioSizeStyle, setAudioSizeStyle] = useState(false);

    const [recordedAudio, setRecordedAudio] = useState(null)
    const audioRef = useRef(null);

    function handleAudioFileChange(e) {
        let fileSize = e.target.files[0].size
        // 50MB = 1024 * 1024 * 50 (52428800)
        // 3000kb = 1024 * 3000 (3072000)
        // 3mb = 1024 * 1024 * 3 (3145728)
        if (e.target.files.length !== 0) {
            if (fileSize < 3145728) {
                setAudioFile(URL.createObjectURL(e.target.files[0]));

                setAudioStyle(true);

                setMediaToUpload(e.target.files[0])
                setAudioSizeStyle(false);
            } else {
                setAudioSizeStyle(true);
            }
        }
    }

    const HandleChooseAudio = event => {
        hiddenAudioRef.current.click();
    };

    const handleAudioFileClick = (e) => {
        e.target.value = null
    }

    useEffect(() => {
        async function convertAudioToFile() {
            const mediaBlob = await fetch(recordedAudio.url)
                .then(response => response.blob());
            const videoFile = new File([mediaBlob], "demo.mp3", { type: "audio/mp3" });
            setMediaToUpload(videoFile)
        }
        if (recordedAudio !== null) {
            convertAudioToFile()
        }
        // eslint-disable-next-line
    }, [recordedAudio])

    return (
        <>
            <div className="text-center">
                <small className="text-muted">Max Recording Time (3 minutes)</small>
            </div>
            <div className="mediaBorder">
                <TakeAudioV2 setRecordedAudio={setRecordedAudio} audioRef={audioRef} />
            </div>
            <div className="mb-3">
                {/* Preview Audio for recording option */}
                <audio src={audioFile} controls width="100%" id="ut-record-preview" className={audioStyle ? 'd-block mb-2 review-audio-container' : 'd-none'} />

                <div className="col-12 text-center my-2">
                    <span>&nbsp; OR &nbsp;</span>
                </div>

                <button type="button" id="choose-audio-btn"
                    // disabled={chooseAudioBtn}
                    style={mediaUploadBtnStyle} className="btn btn-outline-primary upload-btn-mobile col-12" onClick={HandleChooseAudio}>Choose Audio</button>
                <input type="file" name="file" accept="audio/*" className="form-control set-input-dark d-none"
                    id="ut-audio-upload"
                    onChange={handleAudioFileChange} onClick={handleAudioFileClick} ref={hiddenAudioRef} />

                <span className={audioSizeStyle ? 'text-danger d-block' : 'd-none'} id="ut-revAsset-size-error">Audio File cannot be more than 3 MB</span>
                {mediaError && (
                    <span className="text-danger">
                        {mediaError}
                    </span>
                )}
            </div>
        </>
    )
}

export default AudioPicker