import React, { useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { XBlock, XMasonry } from "react-xmasonry";
import ImageViewer from "react-simple-image-viewer";

function EndlessReviews({
  brandURL,
  stopSpinner,
  fetchReviews,
  lastEvaluatedKey,
  reviewList,
  bndThemeMode,
  hideEndRevMsg,
}) {
  // const [reviewList, setReviewList] = useState([]);
  // const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  // const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [imgToDisplay, setImgToDisplay] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  // const url = `https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/${isInitialLoad ? '1/' : ''}` + brandURL + "/widget1"

  const openImageViewer = useCallback((image) => {
    setImgToDisplay(image);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setImgToDisplay(null);
    setIsViewerOpen(false);
  };

  const ReviewImageComponent = ({ imageURL, idx }) => {
    return (
      <>
        <div key={idx} className="col m-2">
          <img
            src={imageURL}
            className={
              imageURL.includes("photo_taking.png") ? "img-fluid" : "img-fluid"
            }
            alt=""
            onClick={() => {
              openImageViewer(imageURL);
            }}
          />
        </div>
        {idx % 2 === 1 && <div className="w-100"></div>}
      </>
    );
  };
  return (
    <div className="container px-3">
      {reviewList.length > 0 ? (
        <InfiniteScroll
          dataLength={reviewList.length}
          hasMore={stopSpinner && lastEvaluatedKey !== null}
          next={fetchReviews}
          loader={<h5>Loading more reviews...</h5>}
          endMessage={
            !hideEndRevMsg && (
              <div className="text-center mt-4 mb-4">
                <h5>End of Reviews</h5>
              </div>
            )
          }
        >
          <XMasonry maxColumns={4} center={false}>
            {reviewList.map((review, index) => (
              <XBlock key={index}>
                <div className="col-lg-12 mb-3">
                  <div
                    className={
                      bndThemeMode === "dark"
                        ? "card-review-dark"
                        : "card-review"
                    }
                  >
                    <div className="d-flex justify-content-between px-3 pt-3 pb-2">
                      <div className="d-flex flex-row align-items-center">
                        {review.revProfileImg === "" ||
                        review.revProfileImg === null ? (
                          <div className="profile-default-Image pt-2 pb-2">
                            <p>{review.revName.charAt(0)}</p>
                          </div>
                        ) : (
                          <img
                            src={review.revProfileImg}
                            width="50px"
                            height="50px"
                            alt=""
                            className="rounded-circle"
                          />
                        )}
                        <div className="d-flex flex-column ms-2 review-header-column">
                          {review.revWebsite ? (
                            <a
                              className="underline-on-hover font-weight-bold lh-sm review-name set-text-white"
                              target="_blank"
                              rel="noreferrer"
                              href={review.revWebsite}
                            >
                              {review.revName}
                            </a>
                          ) : (
                            <span className="font-weight-bold lh-sm review-name set-text-white">
                              {review.revName}
                            </span>
                          )}
                          <small className="text-primary lh-sm review-name set-text-light-white">
                            {review.revTitleCom}
                          </small>
                          <small className="text-muted lh-sm set-text-light-white">
                            {new Date(review.createdAt).toLocaleDateString(
                              "en-UK",
                              { year: "numeric", month: "long", day: "numeric" }
                            )}
                          </small>
                        </div>
                      </div>
                      <div className="d-flex flex-row align-self-center">
                        {review.revSocialProvider === "Twitter" && (
                          <i className="bi bi-twitter"></i>
                        )}
                        {review.revSocialProvider === "Google Map" && (
                          <i className="bi bi-map"></i>
                        )}
                      </div>
                    </div>

                    {review.revContent !== "P undefined" &&
                      review.revContent !== "P " && (
                        <div className="pt-2 pb-3 px-3">
                          <div className="lh-sm text-justify set-text-white">
                            {review.revContent}
                          </div>
                        </div>
                      )}

                    {review.revType === "image" && review.revAsset && (
                      <div className="row g-0">
                        {review.revAsset.split(", ").map((imageURL, idx) => (
                          <ReviewImageComponent
                            imageURL={imageURL}
                            idx={idx}
                            key={idx}
                          />
                        ))}
                      </div>
                    )}

                    {review.revType === "video" && (
                      // <ReactPlayer url={review.revAsset} controls={true} width="100%" height="200px" className="set-video-dark"/>
                      <video
                        src={review.revAsset + "#t=0.1"}
                        controls={true}
                        controlsList="nodownload"
                        width="100%"
                        height="100%"
                        preload="metadata"
                        className={
                          review.revAsset.includes("demo.mp4")
                            ? "set-video-dark"
                            : "set-video-dark"
                        }
                      />
                    )}

                    {review.revType === "audio" && (
                      <audio
                        src={review.revAsset}
                        controls
                        style={{ width: "100%" }}
                        className={
                          bndThemeMode === "dark"
                            ? "review-audio-container"
                            : "review-audio-container-dark"
                        }
                      />
                    )}

                    {isViewerOpen && (
                      <ImageViewer
                        src={[imgToDisplay]}
                        currentIndex={0}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                      />
                    )}
                  </div>
                </div>
              </XBlock>
            ))}
          </XMasonry>
        </InfiniteScroll>
      ) : (
        <div className="set-text-white">End of Reviews</div>
      )}
    </div>
  );
}

export default EndlessReviews;
