import React from 'react'
import { Helmet } from "react-helmet-async";

export default function NotfoundComponent({brandURL}) {
  return (
    <div className= "not-found-card px-5 py-5 anr-centered shadow-lg">
    <Helmet>
      <meta charSet="utf-8" />
      <title>{brandURL}</title>
      <meta name="description" content={"Review About " + brandURL}></meta>
    </Helmet>
    <div className="mb-2 text-center text-decoration-none h4">
      <div className="not-found-create-review-brandname">{brandURL}</div>
  
    </div>

    <div className="text-center not-found-create-review-title-header mb-2" >
    A Nice Review Of <span className="text-uppercase">{brandURL}</span> Is Not Ready
    </div>

    <div className="text-center mb-4" >
    Start Collecting And Sharing Reviews About Your Brand
    </div>

    <div className='text-center'>

      <div className='ms-auto p-1'>
        <a href="https://www.anicereview.com"  className="btn btn-primary" >
          Sign Up For A Free Plan
        </a>
      </div>
      <div className='ms-auto'>
            <a href="https://www.anicereview.com" className='title-header-link' >Collect And Share Reviews For Free 🤝</a>
      </div>
    </div>
  </div> 
   )
}
