import { React, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";

import TextImageReviewForm from "../UI/textImageReviewForm";
import AudioReviewForm from "../UI/audioReviewForm";
import VideoReviewForm from "../UI/videoReviewModal";

import { ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { FadeIn } from "react-slide-fade-in";

import NotfoundComponent from "../UI/notfoundComponent";

function WriteReviewPage() {
  const [showTextImageForm, setShowTextReviewForm] = useState(false);
  const [showAudioForm, setShowAudioForm] = useState(false);
  const [showVideoForm, setShowVideoForm] = useState(false);
  const [pageDesign, setPageDesign] = useState({});

  // Get current URL
  const location = useLocation();
  const brandURL = location.pathname.split("/")[2];

  // edit forms
  const [reviewToEdit, setReviewToEdit] = useState({});
  const [editModeActivated, setEditModeActivated] = useState(false);

  //Delete Forms
  const [reloadReviewList, doReloadReviewList] = useState(false);

  // loading state when waiting for lambda to update count
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [stopSpinner, setStopSpinner] = useState(false);

  const [brandList, SetBrandList] = useState([]);

  const url =
    `https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/` +
    brandURL +
    "/write-review";

  const fetchReviews = () => {
    axios
      .get(url, null, {
        // params: params
      })
      .then((res) => {
        SetBrandList(JSON.parse(res.data.brandDetails).Items);

        let brand = JSON.parse(res.data.brandDetails).Items;

        if (brand) {
          if (brand.collectRevPageDesign) {
            setPageDesign(brand.collectRevPageDesign);
          }
        }
        setTimeout(() => {
          setStopSpinner(true);
        }, 50);
      })
      .catch((err) => {
        console.log("Error fetching data");
      });
  };

  useEffect(() => {
    fetchReviews();

    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // document.body.style.backgroundColor = "#2b2b2b";
    if (
      Object.keys(pageDesign).length > 0 &&
      document.getElementById("rev-options-btn")
    ) {
      // if (pageDesign.bgColor) {
      //   document.body.style.backgroundColor = pageDesign.bgColor;
      // }
      if (pageDesign.btnColor) {
        document.getElementById("rev-options-btn").style.backgroundColor =
          pageDesign.btnColor;
        document.getElementById("rev-options-btn").style.borderColor =
          pageDesign.btnColor;
      }

      if (pageDesign.btnTxtColor) {
        document.getElementById("rev-options-btn").style.color =
          pageDesign.btnTxtColor;
      }
    }
  }, [pageDesign]);

  if (reloadReviewList === true) {
    fetchReviews();

    doReloadReviewList(false);
  }

  return (
    <div style={{ backgroundColor: pageDesign?.bgColor ? pageDesign.bgColor : '#2b2b2b', height: '100vh' }}>
      <TextImageReviewForm
        showModal={showTextImageForm}
        handleClose={() => {
          setShowTextReviewForm(false);
          setReviewToEdit({});
          setEditModeActivated(false);
        }}
        title="Text/Image Review"
        isUpdatingData={isUpdatingData}
        setIsUpdatingData={setIsUpdatingData}
        reviewToEdit={reviewToEdit}
        editModeActivated={editModeActivated}
        brandURL={brandURL}
      />

      <AudioReviewForm
        showModal={showAudioForm}
        handleClose={() => {
          setShowAudioForm(false);
          setReviewToEdit({});
          setEditModeActivated(false);
        }}
        title="Audio Review"
        isUpdatingData={isUpdatingData}
        setIsUpdatingData={setIsUpdatingData}
        editModeActivated={editModeActivated}
        reviewToEdit={reviewToEdit}
        brandURL={brandURL}
      />

      <VideoReviewForm
        showModal={showVideoForm}
        handleClose={() => {
          setShowVideoForm(false);
          setReviewToEdit({});
          setEditModeActivated(false);
        }}
        title="Video Review"
        isUpdatingData={isUpdatingData}
        setIsUpdatingData={setIsUpdatingData}
        editModeActivated={editModeActivated}
        reviewToEdit={reviewToEdit}
        brandURL={brandURL}
      />

      <ToastContainer />

      {stopSpinner === false ? (
        <div className="px-5 pt-4 pb-4 anr-centered">
          <Spinner animation="border" variant="dark" />
        </div>
      ) : (
        <div>
          {Object.keys(brandList).length > 0 ? (
            <div className="create-review-card px-5 py-5 anr-centered shadow-lg">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Write a nice review of {brandList.bndName}</title>
                <meta
                  name="description"
                  content={
                    "Share why " + brandList.bndName + " is a great brand."
                  }
                />
                <meta property="og:site_name" content="A Nice Review" />
                <meta
                  property="og:title"
                  content={"Write a nice review of " + brandList.bndName}
                />
                <meta
                  property="og:description"
                  content={
                    "Share why " + brandList.bndName + " is a great brand."
                  }
                />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="@adrianteoyc" />
                <meta
                  name="twitter:title"
                  content={"Write a nice review of " + brandList.bndName}
                />
                <meta
                  name="twitter:description"
                  content={
                    "Share why " + brandList.bndName + " is a great brand."
                  }
                />
                <meta name="twitter:image" content="" />
              </Helmet>
              <div className="mb-2 text-center text-decoration-none h4">
                <div
                  className="create-review-brandname"
                  style={{ color: pageDesign.bndNameTxtColor }}
                >
                  {brandList.bndName}
                </div>

                <div className="mt-2 mb-4">
                  {brandList.bndLogo && (
                    <img
                      src={brandList.bndLogo}
                      alt={brandList.bndName}
                      className="create-review-brand-logo"
                    />
                  )}
                </div>
              </div>

              <div
                className="text-center create-review-title-header mb-2"
                style={{
                  color: pageDesign.bndTitleColor
                    ? pageDesign.bndTitleColor
                    : "",
                }}
              >
                {pageDesign?.content?.title}
              </div>

              <div
                className="text-center mb-4"
                style={{
                  color: pageDesign.bndMsgColor ? pageDesign.bndMsgColor : "",
                }}
              >
                {pageDesign?.content?.message}
              </div>

              <div className="text-center">
                <div className="ms-auto p-1">
                  <DropdownButton
                    as={ButtonGroup}
                    className="title-header-button"
                    title="Post A Nice Review"
                    id="rev-options-btn"
                  >
                    {pageDesign?.content?.bndOptions && pageDesign.content.bndOptions.includes("image") && (
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => setShowTextReviewForm(true)}
                      >
                        Text / Images Review
                      </Dropdown.Item>
                    )}

                    {pageDesign?.content?.bndOptions && pageDesign.content.bndOptions.includes("audio") && (
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => setShowAudioForm(true)}
                      >
                        Audio Review
                      </Dropdown.Item>
                    )}

                    {pageDesign?.content?.bndOptions && pageDesign.content.bndOptions.includes("video") && (
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => setShowVideoForm(true)}
                      >
                        Video Review
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </div>
                {pageDesign.hideBranding !== true && (
                  <div className="ms-auto p-1">
                    <Link
                      to="https://www.anicereview.com"
                      target="_blank"
                      className="title-header-link"
                    >
                      Powered By A Nice Review 💪
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <FadeIn
              from="bottom"
              positionOffset={0}
              triggerOffset={0}
              delayInMilliseconds={50}
            >
              <NotfoundComponent brandURL={brandURL} />
            </FadeIn>
          )}
        </div>
      )}
    </div>
  );
}

export default WriteReviewPage;
