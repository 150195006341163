import React, { useEffect, useState, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import FormModal from "./formModal";
import axios from "axios";
import TakeVideo from "./takeVideo.js";

import { useReactMediaRecorder } from "react-media-recorder";
import {
  customToastCloseBtn,
  ReviewShowLessBtn,
  ReviewShowMoreBtn,
} from "../hooks/commonFunctions";

function VideoReviewForm({
  showModal,
  handleClose,
  title,
  editModeActivated,
  setIsUpdatingData,
  reviewToEdit,
  brandURL,
}) {
  // const { detect } = require("detect-browser");
  // const browser = detect();

  //Take Video Recording
  const videoReference = useRef();
  const [videoCurrentFileList, setCurrentVideoFileList] = useState([]);
  const hiddenVideoRef = useRef();

  const [startRecordingBtn, setStartRecordingBtn] = useState(true);
  const [stopRecordingBtn, setStopRecordingBtn] = useState(false);
  const [chooseVideoBtn, setChooseVideoBtn] = useState(false);
  const [videoWebcam, setVideoWebcam] = useState(true);
  const [videoRecord, setVideoRecord] = useState(false);
  const [videoRecordPrev, setVideoRecordPrev] = useState(false);

  //Show Profile Image Preview
  const [profileFile, setProfileFile] = useState();
  const [currentProfileList, setCurrentProfileList] = useState([]);
  const hiddenProfileImage = React.useRef(null);
  const [profileSizeStyle, setProfileSizeStyle] = useState(false);

  //Show Video File Preview
  const [videoFile, setVideoFile] = useState();
  const [videoStyle, setVideoStyle] = useState(false);
  const [videoSizeStyle, setVideoSizeStyle] = useState(false);

  const [showMore, setShowMore] = useState(false);

  //Make Button Disbaled After Submit
  const [disabled, setDisabled] = useState(false);

  // State to Store video for new library
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [displayVidRecorder, setDisplayVidRecorder] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    control,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const email = useWatch({
    control,
    name: "email",
  });
  const mobile = useWatch({
    control,
    name: "mobile",
  });

  const defaultValues = {
    content: "",
    revAsset: "",
    name: "",
    company: "",
    website: "",
    email: "",
    profileImg: null,
    profileImgType: "",
    profileImgName: "",
    consent: false,
    revFeatured: false,
    revStatus: "draft",
    revType: "",
    source: "",
    mobile: "",
    assetName: "",
    assetType: "",
  };

  useEffect(() => {
    if (editModeActivated) {
      if (reviewToEdit.revType === "video") {
        reset(reviewToEdit);
      }
    }
    // eslint-disable-next-line
  }, [editModeActivated]);

  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({
      // video: true,
      mediaRecorderOptions: {
        mimeType: "video/webm",
      },
      video: {
        facingMode: { exact: "user" },
        echoCancellation: true,
      },
      onStop: () => {
        setVideoWebcam(false);
        setVideoRecord(false);
        setVideoRecordPrev(true);

        setStartRecordingBtn(true);
        setStopRecordingBtn(false);
        setChooseVideoBtn(false);
        document.getElementById("video_stat").textContent =
          "Recording Stopped!";
      },
      onStart: () => {
        setVideoStyle(false);
        setCurrentVideoFileList([]);
        setChooseVideoBtn(true);
        document.getElementById("video_stat").textContent =
          "Recording Start ...";
        setVideoWebcam(false);
        setVideoRecord(true);
        setVideoRecordPrev(false);

        setStartRecordingBtn(false);
        setStopRecordingBtn(true);

        let countdown = 10000;
        setTimeout(function () {
          stopRecording();
        }, countdown);
      },
    });

  const VideoPreview = ({ stream }) => {
    useEffect(() => {
      if (videoReference.current && stream) {
        videoReference.current.srcObject = stream;
      }

      // eslint-disable-next-line
    }, []);

    if (!stream) {
      return null;
    }

    return (
      <video
        ref={videoReference}
        autoPlay={true}
        width="100%"
        style={{ background: "#000", transform: "rotateY(180deg)" }}
      />
    );
  };

  const handleFileBtnClick = (event) => {
    hiddenProfileImage.current.click();
  };

  function handleProfileFileChange(e) {
    let fileSize = e.target.files[0].size;
    // 50MB = 1024 * 1024 * 50
    if (e.target.files.length !== 0) {
      if (fileSize < 52428800) {
        setProfileFile(URL.createObjectURL(e.target.files[0]));

        setCurrentProfileList(e.target.files[0]);
        setProfileSizeStyle(false);
      } else {
        setProfileSizeStyle(true);
      }
    }
  }

  function handleVideoFileChange(e) {
    let fileSize = e.target.files[0].size;
    // 300MB = 1024 * 1024 * 300
    if (e.target.files.length !== 0) {
      if (fileSize < 314572800) {
        setVideoFile(URL.createObjectURL(e.target.files[0]));
        setRecordedVideo(null);

        setVideoStyle(true);
        setCurrentVideoFileList(e.target.files[0]);

        setVideoSizeStyle(false);
        setDisplayVidRecorder(false);
      } else {
        setVideoSizeStyle(true);
      }
    }
  }

  const onCreateFormSubmit = async (data) => {
    let videoFileList = "";

    if (videoCurrentFileList.name === undefined && recordedVideo === null) {
      toast.error("Please upload a Audio File");
    } else if (!data.email && !data.mobile) {
      toast.error("Please enter email address or mobile number");
    } else if (videoSizeStyle === true) {
      toast.error("Video File cannot be more than 300MB");
    } else {
      const loadingToast = toast.loading("Sending Review...");

      setDisabled(true);

      const profile_Img = currentProfileList;

      const review_Video = videoCurrentFileList;

      let formAsset;
      let formProfile;

      if (!data.content) {
        data.content = "";
      }
      if (recordedVideo) {
        videoFileList = recordedVideo;
      }

      // Check if there is profile image uploaded
      if (!profile_Img || profile_Img.length === 0) {
        data.profileImgName = "";
        data.profileImgType = "";
      } else {
        data.profileImgName = profile_Img.name;
        data.profileImgType = profile_Img.type;

        formProfile = profile_Img;
      }

      data.revType = "video";

      // Check whether video is recorded or choosen from gallery
      if (review_Video.name !== undefined) {
        data.assetName = review_Video.name;
        data.assetType = review_Video.type;
      } else if (videoFileList.name !== undefined) {
        data.assetName = videoFileList.name;
        data.assetType = videoFileList.type;
      }

      data.brandURL = brandURL;

      // const POST_url = "https://hoq2xn5atk.execute-api.ap-southeast-1.amazonaws.com/default/anrv1reviewpost"
      const POST_url =
        "https://2soykez3pj.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewpost";

      await axios
        .post(POST_url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          let imageSignedURL = res.data.assetURL;
          let profileSignedURL = res.data.profileImgURL;

          // Check whether video is recorded or choosen from gallery
          if (review_Video.name !== undefined) {
            formAsset = review_Video;
          } else if (videoFileList.name !== undefined) {
            formAsset = videoFileList;
          } else {
            formAsset = data.revAsset;
          }

          await axios.put(imageSignedURL, formAsset).catch(function (error) {
            console.log("Error Uploading Image");
          });

          if (profile_Img) {
            await axios
              .put(profileSignedURL, formProfile)
              .catch(function (error) {
                console.log("Error Uploading Profile Image");
              });
          }

          handleClose();

          toast.update(loadingToast, {
            render: "Appreciate your support! Thank you.",
            type: "success",
            isLoading: false,
            autoClose: false,
            closeButton: customToastCloseBtn,
          });

          setShowMore(false);
          setIsUpdatingData(true);
          setTimeout(() => {
            setIsUpdatingData(false);
            reset(defaultValues);

            setDisabled(false);

            setProfileFile();
            setCurrentProfileList([]);
            setVideoFile();
            setVideoStyle(false);
            setCurrentVideoFileList([]);

            setStartRecordingBtn(true);
            setStopRecordingBtn(false);
            setChooseVideoBtn(false);

            setVideoWebcam(true);
            setVideoRecord(false);
            setVideoRecordPrev(false);
            setDisplayVidRecorder(true);

            // doReloadReviewList(!reloadReviewList);
          }, 2500);
        })
        .catch((error) => {
          // console.log(error);

          toast.update(loadingToast, {
            render: "Error Sending Review",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        });
    }
  };

  const HandleChooseVideo = (event) => {
    hiddenVideoRef.current.click();
  };

  const handleVideoFileClick = (e) => {
    e.target.value = null;
  };

  useEffect(() => {
    if (
      showModal === true &&
      ["", undefined].includes(email) &&
      ["", undefined].includes(mobile)
    ) {
      setError("eitherRequired", {
        type: "custom",
        message: "Please fill in either email or mobile number",
      });
    } else {
      clearErrors("eitherRequired");
    }
    // eslint-disable-next-line
  }, [email, mobile]);

  useEffect(() => {
    if (showModal && videoStyle === false && videoRecord === false) {
      setError("revAsset", {
        type: "custom",
        message: "Please upload a Video File",
      });
    } else {
      clearErrors("revAsset");
    }
    // eslint-disable-next-line
  }, [videoStyle, videoRecord]);

  return (
    <FormModal
      showModal={showModal}
      handleClose={() => {
        handleClose();
        reset(defaultValues);
        setShowMore(false);

        setProfileFile();
        setCurrentProfileList([]);
        setVideoFile();
        setVideoStyle(false);

        stopRecording();
        setDisabled(false);
        setDisplayVidRecorder(true);

        setTimeout(() => {
          setStartRecordingBtn(true);
          setStopRecordingBtn(false);
          setChooseVideoBtn(false);

          setVideoWebcam(true);
          setVideoRecord(false);
          setVideoRecordPrev(false);
        }, 200);
      }}
      title={editModeActivated ? "Update " + title : "Write " + title}
      submitButton={
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            if (
              ["", undefined].includes(email) &&
              ["", undefined].includes(mobile)
            ) {
              setError("eitherRequired", {
                type: "custom",
                message: "Please fill in either email or mobile number",
              });
            }
            if (videoStyle === false && recordedVideo === null) {
              setError("revAsset", {
                type: "custom",
                message: "Please upload a Video File",
              });
            }
            if (!editModeActivated) {
              handleSubmit(onCreateFormSubmit)();
            }
          }}
          disabled={disabled}
        >
          Submit
        </button>
      }
    >
      <form>
        <div className="d-none">
          <input type="text" {...register("assetName")} />
          <input type="text" {...register("assetType")} />

          <input type="text" {...register("profileImgName")} />
          <input type="text" {...register("profileImgType")} />
        </div>

        <div className="text-center">
          <small className="text-muted">Max Recording Time (3 minutes)</small>
        </div>
        <div className="mb-3">
          {/* <div className="text-center mb-1">
            <span>
              Status: <span id="video_stat">Not Recording</span>
            </span>
          </div> */}
          {/* {(browser.name === "safari" ||
            navigator.userAgent.includes("iPhone")) && (
              <div className="text-center mb-1">
                <p style={{ color: "red" }}>
                  Note: Video Recording is not be working well in Safari Browser.
                  <br />
                  We advised to use Chrome Browser or use Choose Video Instead.
                </p>
              </div>
            )} */}

          {displayVidRecorder && (
            <TakeVideo setRecordedVideo={setRecordedVideo} />
          )}

          <video
            src={videoFile}
            controls={true}
            width="100%"
            height="100%"
            className={videoStyle === true ? "d-block mb-3" : "d-none"}
          ></video>

          {/* <video
            id="video-webcam"
            autoPlay={true}
            width="100%"
            style={{ background: "#000" }}
            className={videoWebcam ? "d-block" : "d-none"}
          ></video> */}

          <div id="video-record" className={videoRecord ? "d-block" : "d-none"}>
            <VideoPreview stream={previewStream} />
          </div>
          <div className="text-center mt-2 mb-2">
            {/* <video
              id="video-record-preview"
              src={mediaBlobUrl}
              controls
              width="100%"
              className={
                videoRecordPrev ? "mb-3 preview-video-rotate" : "d-none"
              }
            /> */}

            <div className="text-center">
              {/* <button
                type="button"
                id="start-recording"
                className={
                  startRecordingBtn
                    ? "btn btn-outline-primary record-btn-mobile"
                    : "d-none"
                }
                onClick={startRecording}
              >
                Start Recording
              </button> */}
              <button
                type="button"
                id="stop-recording"
                className={
                  stopRecordingBtn
                    ? "btn btn-outline-primary record-btn-mobile"
                    : "d-none"
                }
                onClick={stopRecording}
              >
                Stop Recording
              </button>

              <div className="col-12 text-center my-2">
                <span>&nbsp; OR &nbsp;</span>
              </div>

              <button
                type="button"
                id="choose-video-btn"
                disabled={chooseVideoBtn}
                className="btn btn-outline-primary upload-btn-mobile col-12"
                onClick={HandleChooseVideo}
              >
                Choose Video
              </button>
            </div>
          </div>

          <input
            type="file"
            name="file"
            accept="video/*"
            className="form-control set-input-dark d-none"
            id="ut-video-upload"
            // {...register('revAsset', {
            //     required: {
            //         value: editModeActivated ? (reviewToEdit.revAsset === null) : true,
            //         message: "Please upload a video"
            //     }
            // })}
            onChange={handleVideoFileChange}
            onClick={handleVideoFileClick}
            ref={hiddenVideoRef}
          />

          {errors.revAsset && (
            <span className="text-danger" id="ut-revAsset-error">
              {errors.revAsset.message}
            </span>
          )}
          <span className={videoSizeStyle ? "text-danger d-block" : "d-none"}>
            Video File cannot be more than 300MB
          </span>
        </div>

        <div className="mb-3">
          <label htmlFor="revName">
            Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control set-input-dark"
            id="name"
            placeholder="Your name"
            {...register("name", {
              required: true.valueOf,
            })}
          />
          {errors && errors.name && (
            <span className="text-danger" id="ut-name-error">
              Required field
            </span>
          )}
        </div>
        <div className="d-flex">
          <div className="col-lg-5">
            <label htmlFor="revEmail">
              Email address <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control set-input-dark"
              id="revEmail"
              aria-describedby="emailHelp"
              autoFocus
              {...register("email", {
                pattern: {
                  value:
                    /^([a-zA-Z0-9_\\-\\.]{1,})@([a-zA-Z0-9_\\-\\.]{1,}).([a-zA-Z]{2,5})$/,
                  message: "Please enter correct format",
                },
              })}
            />
            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
            {errors && errors.email && (
              <span className="text-danger" id="ut-revEmail-error">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="wrapper">
            <div className="line"></div>
            <div className="wordwrapper">
              <span className="word set-input-dark">or</span>
            </div>
          </div>

          <div className="col-lg-5">
            <label htmlFor="revMobileNo">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control set-input-dark"
              id="mobile"
              {...register("mobile", {
                // required: { value: true, message: "Field is required" },
                pattern: {
                  value: /^([0-9]{1,}[0-9]{5,})$/,
                  message: "Please enter correct format (E.g. 6591234567)",
                },
              })}
            />
            {errors && errors.mobile && (
              <span className="text-danger" id="ut-mobile-error">
                {errors.mobile.message}
              </span>
            )}
          </div>
        </div>
        {errors && errors.eitherRequired && (
          <div className="text-center mt-0 mb-2">
            <span className="text-danger" id="ut-eitherRequired-error">
              {errors.eitherRequired.message}
            </span>
          </div>
        )}

        {!showMore || (
          <div className="my-3">
            <label>Content: </label>
            <textarea
              name="content"
              rows="3"
              className="form-control set-input-dark"
              placeholder="Content"
              maxLength={2048}
              {...register("content")}
            ></textarea>
            {errors && errors.content && (
              <span className="text-danger">{errors.content.message}</span>
            )}
          </div>
        )}

        {!showMore || (
          <div className="mb-3">
            <label htmlFor="company">Review Title: </label>
            <input
              type="text"
              className="form-control set-input-dark"
              id="company"
              placeholder="Review Title"
              {...register("company")}
            />
            {errors && errors.company && (
              <span className="text-danger">Required Field</span>
            )}
          </div>
        )}

        {!showMore || (
          <div className="mb-3">
            <label htmlFor="revWebsite">Website: </label>
            <input
              placeholder="Website"
              type="url"
              className="form-control set-input-dark"
              {...register("website", {
                pattern: {
                  value:
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Please enter correct format",
                },
              })}
            />
            {errors && errors.website && (
              <span className="text-danger">{errors.website.message}</span>
            )}
          </div>
        )}

        {!showMore || (
          <div>
            <label htmlFor="profileImg">Profile Picture: </label>
            <div className="p-2">
              <img
                src={profileFile}
                width="50px"
                height="50px"
                alt=""
                className="rounded-circle shadow-sm"
              />

              <span
                name="profileInput"
                className="btn btn-primary mx-3"
                onClick={handleFileBtnClick}
              >
                Upload{" "}
              </span>
            </div>

            <input
              type="file"
              name="image"
              accept="image/*"
              className="form-control set-input-dark d-none"
              {...register("profileImg")}
              ref={hiddenProfileImage}
              onChange={handleProfileFileChange}
            />
            <span
              className={profileSizeStyle ? "text-danger d-block" : "d-none"}
            >
              Profile Image File cannot be more than 50MB
            </span>
          </div>
        )}

        <div className="mt-3">
          <label htmlFor="consent">
            Consent To Publish Review <span className="text-danger">*</span>
          </label>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                id="cbConsent"
                name="cbConsent"
                {...register("consent", { required: true })}
                className="form-check-input"
              />
              Yes
            </label>
          </div>
          {errors.consent && (
            <span className="text-danger d-block" id="ut-cbConsent-error">
              Consent is required
            </span>
          )}
        </div>

        <div className="pt-2">
          <span id="more-options" onClick={() => setShowMore(!showMore)}>
            {showMore ? <ReviewShowLessBtn /> : <ReviewShowMoreBtn />}{" "}
          </span>
        </div>
      </form>
    </FormModal>
  );
}

export default VideoReviewForm;
