import { React, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { FadeIn } from "react-slide-fade-in";
import WriteReview2 from "../UI/custom-forms/writeReview2"

import NotfoundComponent from "../UI/notfoundComponent";

const CustomFormReview = () => {
    const [formToShow, setFormToShow] = useState(null);
    // const [pageDesign, setPageDesign] = useState({});
    const [pageStyling, setPageStyling] = useState(null);
    const [mediaIsRequired, setMediaIsRequired] = useState(false);

    // Get current URL
    const location = useLocation();
    const brandURL = location.pathname.split("/")[2];
    const formID = location.pathname.split("/")[4]

    const url = `https://w39wh0twqf.execute-api.ap-southeast-1.amazonaws.com/default/anrv2customreviewget-dev/c/${brandURL}/${formID}`;

    // loading state when waiting for lambda to update count
    const [isUpdatingData, setIsUpdatingData] = useState(false);
    const [stopSpinner, setStopSpinner] = useState(false);

    const [brandList, SetBrandList] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const fetchReviews = () => {
        axios
            .get(url, null, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then((res) => {
                if (res.data.success) {
                    if (res.data.success.brandDetails) {
                        SetBrandList(JSON.parse(res.data.success.brandDetails))
                    }
                    if (res.data.success.formData) {
                        setFormDetails(JSON.parse(res.data.success.formData))

                        if (JSON.parse(res.data.success.formData).design) {
                            // setPageStyling(JSON.parse(res.data.success.formData).design)
                            const design = JSON.parse(res.data.success.formData).design
                            setPageStyling({
                                reviewPage: {
                                    "btnBgColor": design?.btnBgColor,
                                    "btnTxtColor": design?.btnTxtColor,
                                    "buttonRadius": design?.buttonRadius,
                                    "cardBorderColor": design?.cardBorderColor,
                                    "cardBgColor": design?.cardBgColor,
                                    "cardRadius": design?.cardRadius,
                                    "cardShadow": design?.cardShadow,
                                    "contentBgColor": design?.contentBgColor,
                                    "textColor": design?.textColor
                                },
                                writeReviewPage: {
                                    "formBorderRadius": design?.formBorderRadius,
                                    "formBorderColor": design?.formBorderColor,
                                    "formBgColor": design?.formBgColor,
                                    "formTxtColor": design?.formTxtColor,
                                    "closeIconColor": design?.closeIconColor,
                                    "mediaBgColor": design?.mediaBgColor,
                                    "mediaBtnRadius": design?.mediaBtnRadius,
                                    "mediaTxtColor": design?.mediaTxtColor,
                                    "submitBgColor": design?.submitBgColor,
                                    "submitBtnRadius": design?.submitBtnRadius,
                                    "submitTxtColor": design?.submitTxtColor,
                                },
                                thankYouPage: {
                                    "tyBgColor": design?.tyBgColor,
                                    "tyBorderColor": design?.tyBorderColor,
                                    "tyCardRadius": design?.tyCardRadius,
                                    "tyCardShadow": design?.tyCardShadow,
                                    "tyCardTxtColor": design?.tyCardTxtColor,
                                    "tyCTABgColor": design?.tyCTABgColor,
                                    "tyCTARadius": design?.tyCTARadius,
                                    "tyCTATxtColor": design?.tyCTATxtColor
                                }
                            })
                        }
                    }

                }
                setTimeout(() => {
                    setStopSpinner(true);
                }, 50);
            })
            .catch((err) => {
                console.log('Failed to fetch information');
            });
    };

    useEffect(() => {
        fetchReviews();

        ReactGA.send({ hitType: "pageview", page: location.pathname });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (formToShow !== "text") {
            setMediaIsRequired(true)
        }
    }, [formToShow])

    return (
        <>
            <div
                style={{ backgroundColor: pageStyling?.reviewPage?.contentBgColor, height: '100%' }}
            >
                <WriteReview2 showModal={formToShow !== null}
                    handleClose={(e) => setFormToShow(null)} title="Create Review" isUpdatingData={isUpdatingData}
                    setIsUpdatingData={setIsUpdatingData} brandURL={brandURL}
                    owner={brandList?.owner} formToShow={formToShow} mediaIsRequired={mediaIsRequired} setMediaIsRequired={setMediaIsRequired}
                    formFields={formDetails?.formFields} others={{
                        consentMessage: formDetails?.others?.consentMessage,
                        formData: formDetails,
                        formID: formID
                    }}
                    pageDesign={pageStyling ? pageStyling : {}}
                />

                <ToastContainer />

                {stopSpinner === false ? (
                    <div className="px-5 pt-4 pb-4 anr-centered">
                        <Spinner animation="border" variant="dark" />
                    </div>
                ) : (
                    <div className="container d-flex justify-content-center align-items-center h-100 ">
                        <div className="row px-5">
                            {Object.keys(brandList).length > 0 ? (
                                <div className="create-review-card px-md-5 py-md-5 px-3 py-5"
                                    style={{
                                        backgroundColor: pageStyling?.reviewPage?.cardBgColor,
                                        borderColor: pageStyling?.reviewPage?.cardBorderColor,
                                        borderRadius: pageStyling?.reviewPage?.cardRadius ? pageStyling.reviewPage.cardRadius + 'px' : '',
                                        color: pageStyling?.reviewPage?.textColor,
                                        boxShadow: pageStyling?.reviewPage?.cardShadow
                                    }}>
                                    <Helmet>
                                        <meta charSet="utf-8" />
                                        <title>Write a nice review of {brandList.bndName}</title>
                                        <meta
                                            name="description"
                                            content={
                                                "Share why " + brandList.bndName + " is a great brand."
                                            }
                                        />
                                        <meta property="og:site_name" content="A Nice Review" />
                                        <meta
                                            property="og:title"
                                            content={"Write a nice review of " + brandList.bndName}
                                        />
                                        <meta
                                            property="og:description"
                                            content={
                                                "Share why " + brandList.bndName + " is a great brand."
                                            }
                                        />
                                        <meta property="og:type" content="website" />
                                        <meta property="og:image" content="" />
                                        <meta name="twitter:card" content="summary" />
                                        <meta name="twitter:creator" content="@adrianteoyc" />
                                        <meta
                                            name="twitter:title"
                                            content={"Write a nice review of " + brandList.bndName}
                                        />
                                        <meta
                                            name="twitter:description"
                                            content={
                                                "Share why " + brandList.bndName + " is a great brand."
                                            }
                                        />
                                        <meta name="twitter:image" content="" />
                                    </Helmet>
                                    <div className="mb-2 text-center text-decoration-none h4">
                                        <div
                                            className="create-review-brandname"
                                        // style={{ color: pageDesign.bndNameTxtColor }}
                                        >
                                            {brandList.bndName}
                                        </div>

                                        <div className="mt-2 mb-4">
                                            {formDetails?.others?.bndLogo ? (
                                                <img
                                                    src={formDetails.others.bndLogo}
                                                    className="create-review-brand-logo"
                                                    alt="brand-logo"
                                                />
                                            ) : (
                                                brandList.bndLogo && (
                                                    <img
                                                        src={brandList.bndLogo}
                                                        alt={brandList.bndName}
                                                        className="create-review-brand-logo"
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className="text-center create-review-title-header mb-2"
                                    // style={{
                                    //     color: pageDesign.bndTitleColor
                                    //         ? pageDesign.bndTitleColor
                                    //         : "",
                                    // }}
                                    >
                                        {formDetails?.welcome?.title}
                                    </div>

                                    {formDetails?.welcome?.message && (
                                        <div className="text-center mb-4"
                                            dangerouslySetInnerHTML={{ __html: formDetails.welcome.message }} />
                                    )}

                                    <div className="text-center">
                                        <div className="ms-auto p-1">
                                            {/* <DropdownButton
                                                as={ButtonGroup}
                                                className="title-header-button"
                                                style={{backgroundColor: pageStyling?.reviewPage?.btnBgColor}}
                                                title={formDetails?.welcome?.shareBtn ? formDetails.welcome.shareBtn : "Post A Nice Review"}
                                                id="rev-options-btn"
                                            > */}
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    style={{
                                                        backgroundColor: pageStyling?.reviewPage?.btnBgColor,
                                                        borderColor: pageStyling?.reviewPage?.btnBgColor,
                                                        color: pageStyling?.reviewPage?.btnTxtColor,
                                                        borderRadius: pageStyling?.reviewPage?.buttonRadius ? pageStyling.reviewPage.buttonRadius + "px" : '',
                                                    }} id="dropdown-basic">
                                                    {formDetails?.welcome?.shareBtn ? formDetails.welcome.shareBtn : "Post A Nice Review"}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {(formDetails?.others?.typesOfReviews ? formDetails.others.typesOfReviews : []).includes("text") && (
                                                        <Dropdown.Item
                                                            eventKey="1"
                                                            onClick={() => setFormToShow('text')}
                                                        >
                                                            {formDetails.welcome.shareTextBtn ? formDetails.welcome.shareTextBtn : "Text Review"}
                                                        </Dropdown.Item>
                                                    )}

                                                    {(formDetails?.others?.typesOfReviews ? formDetails.others.typesOfReviews : []).includes("photo") && (
                                                        <Dropdown.Item
                                                            eventKey="2"
                                                            onClick={() => setFormToShow('image')}
                                                        >
                                                            {formDetails.welcome.sharePhotoBtn ? formDetails.welcome.sharePhotoBtn : "Photo Review"}
                                                        </Dropdown.Item>
                                                    )}

                                                    {(formDetails?.others?.typesOfReviews ? formDetails.others.typesOfReviews : []).includes("audio") && (
                                                        <Dropdown.Item
                                                            eventKey="3"
                                                            onClick={() => setFormToShow('audio')}
                                                        >
                                                            {formDetails.welcome.shareAudioBtn ? formDetails.welcome.shareAudioBtn : "Audio Review"}
                                                        </Dropdown.Item>
                                                    )}

                                                    {(formDetails?.others?.typesOfReviews ? formDetails.others.typesOfReviews : []).includes("video") && (
                                                        <Dropdown.Item
                                                            eventKey="4"
                                                            onClick={() => setFormToShow('video')}
                                                        >
                                                            {formDetails.welcome.shareVideoBtn ? formDetails.welcome.shareVideoBtn : "Video Review"}
                                                        </Dropdown.Item>
                                                    )}
                                                    {/* </DropdownButton> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                        {formDetails?.others?.hideBranding !== true && (
                                            <div className="ms-auto p-1">
                                                <Link
                                                    to="https://www.anicereview.com"
                                                    target="_blank"
                                                    className="title-header-link"
                                                >
                                                    Powered By A Nice Review 💪
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <FadeIn
                                    from="bottom"
                                    positionOffset={0}
                                    triggerOffset={0}
                                    delayInMilliseconds={50}
                                >
                                    <NotfoundComponent brandURL={brandURL} />
                                </FadeIn>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default CustomFormReview