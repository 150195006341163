import React, { useEffect, useState, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import FormModal from "./formModal";
import axios from "axios";

// import CameraPhoto, { FACING_MODES } from "jslib-html5-camera-photo";
// import { Camera } from "react-camera-pro";
import TakePhotoV2 from "./takePhotoV2";
// import SampleCamera from "./testTakePhoto";
import {
  customToastCloseBtn,
  ReviewShowLessBtn,
  ReviewShowMoreBtn,
} from "../hooks/commonFunctions";

function TextImageReviewForm({
  showModal,
  handleClose,
  title,
  // setIsUpdatingData,
  reviewToEdit,
  editModeActivated,
  brandURL,
}) {
  // const { detect } = require("detect-browser");
  // const browser = detect();
  const [isUpdatingData, setIsUpdatingData] = useState(false)

  //Show Profile Image Preview
  const [profileFile, setProfileFile] = useState();
  const [currentProfileList, setCurrentProfileList] = useState([]);
  const hiddenProfileImage = React.useRef(null);
  const [profileSizeStyle, setProfileSizeStyle] = useState(false);

  //Show Image File Preview
  const [imageFile, setImageFile] = useState();
  const [imageStyle, setImageStyle] = useState(false);
  const [imageSizeStyle, setImageSizeStyle] = useState(false);

  const [showMore, setShowMore] = useState(false);

  //Make Button Disbaled After Submit
  const [disabled, setDisabled] = useState(false);

  //Camera
  const [PhotoFileArray, setPhotoFileArray] = useState([]);

  const hiddenImageRef = useRef();
  const [currentImageList, setCurrentImageList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    control,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const email = useWatch({
    control,
    name: "email",
  });
  const mobile = useWatch({
    control,
    name: "mobile",
  });

  const defaultValues = {
    content: "",
    revAsset: "",
    name: "",
    company: "",
    website: "",
    email: "",
    profileImg: null,
    profileImgType: "",
    profileImgName: "",
    consent: false,
    revFeatured: false,
    revStatus: "draft",
    revType: "",
    source: "",
    mobile: "",
    assetName: "",
    assetType: "",
  };

  // Test new camera
  // const [image, setImage] = useState(null);
  // new Camera
  const [showNewCamera, setShowNewCamera] = useState(true);
  // So that we can close/off the camera on laptop
  const cameraRef = useRef(null);

  useEffect(() => {
    if (editModeActivated) {
      if (reviewToEdit.revType === "text" || reviewToEdit.revType === "image") {
        reset(reviewToEdit);
      }
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [editModeActivated]);

  const handleFileBtnClick = (event) => {
    if (isUpdatingData !== true) {
      hiddenProfileImage.current.click();
    }
  };

  function handleProfileFileChange(e) {
    let fileSize = e.target.files[0].size;
    // 50MB = 1024 * 1024 * 50
    if (e.target.files.length !== 0) {
      if (fileSize < 52428800) {
        setProfileFile(URL.createObjectURL(e.target.files[0]));

        setCurrentProfileList(e.target.files[0]);

        setProfileSizeStyle(false);
      } else {
        setProfileSizeStyle(true);
      }
    }
  }

  function handleImageFileChange(e) {
    let fileSize = e.target.files[0].size;
    // 50MB = 1024 * 1024 * 50
    if (e.target.files.length !== 0) {
      if (fileSize < 52428800) {
        setShowNewCamera(false);
        cameraRef.current = null;
        setImageFile(URL.createObjectURL(e.target.files[0]));

        setImageStyle(true);
        setCurrentImageList(e.target.files[0]);

        setImageSizeStyle(false);
      } else {
        setImageSizeStyle(true);
      }
    }
  }

  const onCreateFormSubmit = async (data) => {
    if (!data.email && !data.mobile) {
      toast.error("Please enter email address or mobile number");
      setError("email", {
        type: "custom",
        message: "This is required",
      });
      setError("revMobileNo");
    } else if (imageSizeStyle === true) {
      toast.error("Image File cannot be more than 50MB");
    } else {
      const loadingToast = toast.loading("Sending Review...");

      setDisabled(true);

      const profile_Img = currentProfileList;

      const review_Image = currentImageList;

      let formAsset;
      let formProfile;

      // Check if there is profile image uploaded
      if (!profile_Img || profile_Img.length === 0) {
        data.profileImgName = "";
        data.profileImgType = "";
      } else {
        data.profileImgName = profile_Img.name;
        data.profileImgType = profile_Img.type;

        formProfile = profile_Img;
      }

      // Check whether image is taken or choosen from gallery
      if (review_Image.name !== undefined) {
        data.revType = "image";

        //data.revAsset = review_Image
        data.assetName = review_Image.name;
        data.assetType = review_Image.type;
      } else if (PhotoFileArray.name !== undefined) {
        data.revType = "image";

        //data.revAsset = PhotoFileArray
        data.assetName = PhotoFileArray.name;
        data.assetType = PhotoFileArray.type;
      } else {
        data.revType = "text";
      }

      data.brandURL = brandURL;
      // const POST_url = "https://hoq2xn5atk.execute-api.ap-southeast-1.amazonaws.com/default/anrv1reviewpost"
      const POST_url =
        "https://2soykez3pj.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewpost";

      await axios
        .post(POST_url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          // Check whether image is taken or choosen from gallery
          if (review_Image.name !== undefined) {
            formAsset = review_Image;
          } else if (PhotoFileArray.name !== undefined) {
            formAsset = PhotoFileArray;
          } else {
            formAsset = data.revAsset;
          }

          let imageSignedURL = res.data.assetURL;
          let profileSignedURL = res.data.profileImgURL;

          await axios
            .put(imageSignedURL, formAsset)
            // .then(function (response) {
            //     console.log(response)
            // })
            .catch(function (error) {
              console.log("Error uploading Image");
            });

          if (profile_Img) {
            await axios
              .put(profileSignedURL, formProfile)
              // .then(function (response) {
              //     console.log(response)
              // })
              .catch(function (error) {
                console.log("Error Uploading Profile Image");
              });
          }

          handleClose();

          toast.update(loadingToast, {
            render: "Appreciate your support! Thank you.",
            type: "success",
            isLoading: false,
            autoClose: false,
            closeButton: customToastCloseBtn,
          });

          setShowMore(false);
          setIsUpdatingData(true);
          setTimeout(() => {
            setIsUpdatingData(false);
            reset(defaultValues);

            setProfileFile();
            setCurrentProfileList([]);
            setImageFile();
            setImageStyle(false);

            setDisabled(false);

            setShowNewCamera(true);
            setPhotoFileArray([]);
            setCurrentImageList([]);

            // doReloadReviewList(!reloadReviewList);
          }, 2500);
        })
        .catch((error) => {
          // console.log("err", error.response);

          toast.update(loadingToast, {
            render: "Error Sending Review",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        });
    }
  };

  const HandleChoosePhoto = (event) => {
    if (isUpdatingData !== true) {
      hiddenImageRef.current.click();
    }
  };

  useEffect(() => {
    if (
      showModal === true &&
      ["", undefined].includes(email) &&
      ["", undefined].includes(mobile)
    ) {
      setError("eitherRequired", {
        type: "custom",
        message: "Please fill in either email or mobile number",
      });
    } else {
      clearErrors("eitherRequired");
    }
    // eslint-disable-next-line
  }, [email, mobile]);

  return (
    <FormModal
      showModal={showModal}
      handleClose={() => {
        handleClose();
        reset(defaultValues);
        setShowMore(false);

        setProfileFile();
        setCurrentProfileList([]);
        setImageFile();
        setImageStyle(false);
        setShowNewCamera(true);
        cameraRef.current = null;
      }}
      title={editModeActivated ? "Update " + title : "Write " + title}
      submitButton={
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            if (
              ["", undefined].includes(email) &&
              ["", undefined].includes(mobile)
            ) {
              setError("eitherRequired", {
                type: "custom",
                message: "Please fill in either email or mobile number",
              });
            }
            if (!editModeActivated) {
              handleSubmit(onCreateFormSubmit)();
            }
          }}
          disabled={disabled}
        >
          {editModeActivated ? "Save" : "Submit"}
        </button>
      }
    >
      <form className="container">
        <div className="d-none">
          <input type="text" {...register("assetName")} />
          <input type="text" {...register("assetType")} />

          <input type="text" {...register("profileImgName")} />
          <input type="text" {...register("profileImgType")} />
        </div>
        {editModeActivated ? (
          reviewToEdit.revType === "image" && (
            <div className="mb-3">
              <label>Photo: </label>
              <input
                type="file"
                name="image"
                accept="image/*"
                className="form-control set-input-dark"
                {...register("revAsset", {
                  // required: {
                  //   value: editModeActivated ? (reviewToEdit.revAsset === null) : true,
                  //   message: "Please upload an image"
                  // }
                })}
              />
              {errors && errors.revAsset && (
                <span className="text-danger">{errors.revAsset.message}</span>
              )}
              <span
                className={imageSizeStyle ? "text-danger d-block" : "d-none"}
              >
                Image File cannot be more than 50MB
              </span>
            </div>
          )
        ) : (
          //Form Create Image File
          <div className=" row mb-3">
            <div
              className="col-12 mb-3 justify-content-center"
              style={{ height: "auto" }}
            >
              {/* {(browser.name === "safari" || navigator.userAgent.includes("iPhone")) &&
                            <p style={{ color: "red" }}>
                                Note: Take Camera Photo might not be working well in Safari Browser. <br />
                                We advised to use Chrome Browser or use Choose Photo instead.
                            </p>
                            } */}

              {/* Preview Image File */}
              {imageFile && !showNewCamera && (
                <img
                  src={imageFile}
                  id="image-file-Element"
                  width="100%"
                  height="100%"
                  alt=""
                  className={imageStyle ? "d-block" : "d-none"}
                />
              )}

              {/* {image && !showNewCamera && (
                <img
                  src={image}
                  alt=""
                  width="100%"
                  height="100%"
                />
              )} */}

              {showNewCamera && showModal && (
                <TakePhotoV2
                  setPhotoFileArray={setPhotoFileArray}
                  cameraRef={cameraRef}
                />
              )}
            </div>
            <div className="col-12 text-center">
              <span>&nbsp; OR &nbsp;</span>
            </div>
            <div className="col-12 text-center mt-2 mb-2">
              <button
                type="button"
                id="upload-photo-btn"
                className="btn btn-outline-primary upload-btn-mobile col-12"
                onClick={HandleChoosePhoto}
              >
                Choose Photo
              </button>
            </div>

            <input
              type="file"
              name="image"
              accept="image/*"
              id="file-upload-image"
              className="form-control set-input-dark d-none"
              {...register("revAsset", {})}
              onChange={handleImageFileChange}
              ref={hiddenImageRef}
            />

            {errors && errors.revAsset && (
              <span className="text-danger">{errors.revAsset.message}</span>
            )}
            <span className={imageSizeStyle ? "text-danger d-block" : "d-none"}>
              Image File cannot be more than 50MB
            </span>
          </div>
        )}

        <div className="mb-3">
          <label>
            Review Content <span className="text-danger">*</span>{" "}
          </label>
          <textarea
            name="content"
            rows="3"
            className="form-control set-input-dark"
            placeholder="Tell Us What You Like About Our Brand 😀"
            id="ut-content"
            disabled={isUpdatingData === true}
            maxLength={2048}
            {...register("content", {
              required: {
                value: true,
                message: "Content is required",
              },
            })}
          ></textarea>
          {errors && errors.content && (
            <span className="text-danger" id="ut-content-error">
              {errors.content.message}
            </span>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="revName">
            Name <span className="text-danger">*</span>{" "}
          </label>
          <input
            type="text"
            className="form-control set-input-dark"
            id="name"
            disabled={isUpdatingData === true}
            {...register("name", {
              required: true,
            })}
          />
          {errors && errors.name && (
            <span className="text-danger" id="ut-name-error">
              This is a required field
            </span>
          )}
        </div>
        <div className="d-flex">
          <div className="col-lg-5">
            <label htmlFor="revEmail">
              Email address <span className="text-danger">*</span>{" "}
            </label>
            <input
              type="email"
              className="form-control set-input-dark"
              id="revEmail"
              aria-describedby="emailHelp"
              autoFocus
              disabled={isUpdatingData === true}
              {...register("email", {
                pattern: {
                  value:
                    /^([a-zA-Z0-9_\\-\\.]{1,})@([a-zA-Z0-9_\\-\\.]{1,}).([a-zA-Z]{2,5})$/,
                  message: "Please enter correct email format",
                },
              })}
            />
            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
            {errors && errors.email && (
              <span className="text-danger" id="ut-revEmail-error">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="wrapper">
            <div className="line"></div>
            <div className="wordwrapper">
              <span className="word set-input-dark">or</span>
            </div>
          </div>

          <div className="col-lg-5">
            <label htmlFor="revMobileNo">
              Mobile Number <span className="text-danger">*</span>{" "}
            </label>
            <input
              type="text"
              className="form-control set-input-dark"
              id="mobileNo"
              disabled={isUpdatingData === true}
              {...register("mobile", {
                // required: { value: true, message: "Field is required" },
                pattern: {
                  value: /^([0-9]{1,}[0-9]{5,})$/,
                  message: "Please enter correct format (E.g. 6591234567)",
                },
              })}
            />
            {errors && errors.mobile && (
              <span className="text-danger" id="ut-mobileNo-error">
                {errors.mobile.message}
              </span>
            )}
          </div>
        </div>
        {errors && errors.eitherRequired && (
          <div className="text-center mt-0 mb-2" id="ut-eitherRequired-error">
            <span className="text-danger">{errors.eitherRequired.message}</span>
          </div>
        )}
        {!showMore || (
          <div className="my-3">
            <label htmlFor="company">Job Title: </label>
            <input
              type="text"
              className="form-control set-input-dark"
              id="company"
              disabled={isUpdatingData === true}
              placeholder="Founder of A Nice Review"
              {...register("company")}
            />
            {errors && errors.company && (
              <span className="text-danger">Required field</span>
            )}
          </div>
        )}
        {!showMore || (
          <div className="mb-3">
            <label htmlFor="revWebsite">Website: </label>
            <input
              placeholder="https://....com"
              type="url"
              className="form-control set-input-dark"
              disabled={isUpdatingData === true}
              {...register("website", {
                pattern: {
                  value:
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Please enter correct format",
                },
              })}
            />
            {errors && errors.website && (
              <span className="text-danger">{errors.website.message}</span>
            )}
          </div>
        )}

        {!showMore || (
          <div>
            <label htmlFor="profileImg">Profile Photo: </label>
            <div className="p-2">
              <img
                src={profileFile}
                width="50px"
                height="50px"
                alt=""
                className="border-profile"
              />

              <span
                name="profileInput"
                className="btn-sm btn-primary mx-3"
                onClick={handleFileBtnClick}
              >
                Upload{" "}
              </span>
            </div>

            <input
              type="file"
              name="image"
              accept="image/*"
              className="form-control set-input-dark d-none"
              {...register("profileImg")}
              ref={hiddenProfileImage}
              onChange={handleProfileFileChange}
            />
            <span
              className={profileSizeStyle ? "text-danger d-block" : "d-none"}
            >
              Profile Image File cannot be more than 50MB
            </span>
          </div>
        )}

        <div className="mt-3">
          <label htmlFor="consent">
            Consent To Publish Review <span className="text-danger">*</span>{" "}
          </label>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                id="cbConsent"
                name="cbConsent"
                {...register("consent", { required: true })}
                className="form-check-input"
                disabled={isUpdatingData === true}
              />
              Yes
            </label>
          </div>
          {errors.consent && (
            <span className="text-danger d-block" id="ut-cbConsent-error">
              Consent is required
            </span>
          )}
        </div>

        <div className="pt-2">
          <span id="more-options" onClick={() => setShowMore(!showMore)}>
            {showMore ? <ReviewShowLessBtn /> : <ReviewShowMoreBtn />}{" "}
          </span>
        </div>
      </form>
    </FormModal>
  );
}

export default TextImageReviewForm;
