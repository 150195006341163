import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Camera } from "react-camera-pro";
import { useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";

// const Wrapper = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 40%;
// `;
const Parent = styled.div`
  z-index: 0;
  position: relative;
  height: 100%;
  padding-top: 56.25%;
  @media (max-width: 700px) {
    padding-top: 133.3%;
  }
`;

const Control = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 25%;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    height: 70px;
  }
  @media (max-width: 700px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;

const TakePhotoButton = styled(Button)`
  height: 45px;
  width: 45px;
  background-image: url("/cam-icon-2.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  //border: solid 3px black;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    background-size: 90%;
  }
  @media (max-width: 700px) {
    //border: solid 2px black;
    height: 40px;
    width: 40px;
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  &:disabled {
    opacity: 1;
    cursor: default;
  }
  @media (max-width: 700px) {
    width: 30px;
    height: 30px;
  }
`;

// const ImagePreview = styled.div < { image: String | null } > `
//   width: 120px;
//   height: 120px;
//   ${({ image }) => (image ? `background-image:  url(${image});` : '')}
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   @media (max-width: 400px) {
//     width: 50px;
//     height: 120px;
//   }
// `;

const ImagePreview = styled.div.attrs((props) => ({
  image: props.image ? `background-image:  url(${props.image});` : "",
}))`
  width: 35px;
  height: 35px;
  ${(props) => props.image}
  background-size: cover;
  border: 1px solid white;
  background-position: center;
  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
`;

const TakePhotoV2 = ({ setPhotoFileArray, cameraRef }) => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState(null);
  const [showCamera, setShowCamera] = useState(true);
  // to display image in full screen
  const [imgToDisplay, setImgToDisplay] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  // const [isFrontCam, setIsFrontCam] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (numberOfCameras === 0) {
        setShowCamera(false);
      } else {
        setShowCamera(true);
      }
    }, 1500);
  }, [numberOfCameras]);

  const openImageViewer = useCallback((image) => {
    setImgToDisplay(image);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setImgToDisplay(null);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Parent>
        <Camera
          ref={cameraRef}
          aspectRatio="cover"
          numberOfCamerasCallback={setNumberOfCameras}
          errorMessages={{
            noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
            switchCamera:
            'It is not possible to switch camera to different one because there is only one video device accessible.',
            canvas: 'Canvas is not supported.'
          }}
          className="huar"
        />
        {/* {!showCamera && <>Unble to load</>} */}
        {showCamera && (
          <Control>
            <ImagePreview
              image={image}
              onClick={() => {
                openImageViewer(image);
              }}
            />
            <TakePhotoButton
              onClick={(e) => {
                e.preventDefault();
                if (cameraRef.current) {
                  const photo = cameraRef.current.takePhoto();
                  setImage(photo);

                  // convert image
                  var binary = atob(photo.split(",")[1]);
                  var array = [];

                  for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                  }

                  const blobData = new Blob([new Uint8Array(array)], {
                    type: "image/jpeg",
                  });
                  const captureImageFile = new File(
                    [blobData],
                    "photo_taking.png",
                    { type: "image/jpeg" }
                  );
                  setPhotoFileArray(captureImageFile);
                }
              }}
            />
            <ChangeFacingCameraButton
              disabled={numberOfCameras <= 1}
              onClick={(e) => {
                e.preventDefault();
                const cameraMode = cameraRef.current.switchCamera();
              
                // TODO refactor getElementById
                if (cameraMode === "user") {
                  document.getElementById("video").style.transform =
                    "scaleX(-1)";
                } else {
                  document.getElementById("video").style.transform =
                    "scaleX(1)";
                }
              }}
            />
          </Control>
        )}
      </Parent>

      {isViewerOpen && (
        <ImageViewer
          src={[imgToDisplay]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default TakePhotoV2;
