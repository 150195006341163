import React from "react";
import { Modal } from "react-bootstrap";

function FormModal({ showModal, handleClose, title, children, submitButton, size, pageStyle, closeBtnStyle }) {
    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            // dialogClassName="modal-80w"
            aria-labelledby="example-custom-modal-styling-title"
            keyboard={false}
            centered
            size={size || ""}
        >
            <div
                style={pageStyle ? pageStyle : {backgroundColor: "white"}}
            >
                    <Modal.Header closeButton
                        closeVariant={closeBtnStyle?.closeIconColor}
                    >
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                    <Modal.Footer>
                        {submitButton}
                    </Modal.Footer>
            </div>
        </Modal>
    )
}

export default FormModal;