import React, { useCallback, useState } from "react";
import { Carousel } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";

const CarouselReviewDisplay = ({ reviewList, pageStyle }) => {
    const [imgToDisplay, setImgToDisplay] = useState(null);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((image) => {
        setImgToDisplay(image);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setImgToDisplay(null);
        setIsViewerOpen(false);
    };

    const no_of_item_per_slides = 3;

    const updated_ReviewList = [];

    let review_counter = 0;

    for (let i = 0; i < reviewList.length; i += no_of_item_per_slides) {
        let review_left_count = reviewList.length - review_counter;

        if (review_left_count >= 3) {
            updated_ReviewList.push([
                reviewList[i],
                reviewList[i + 1],
                reviewList[i + 2],
            ]);

            review_counter += 3;
        } else if (review_left_count === 2) {
            updated_ReviewList.push([reviewList[i], reviewList[i + 1]]);

            review_counter += 2;
        } else if (review_left_count === 1) {
            updated_ReviewList.push([reviewList[i]]);

            review_counter += 1;
        }
    }

    const ReviewImageComponent = ({ imageURL, idx }) => {
        return (
            <>
                <div key={idx} className="col m-2">
                    <img
                        src={imageURL}
                        className={
                            imageURL.includes("photo_taking.png")
                                ? "img-fluid"
                                : "img-fluid"
                        }
                        alt=""
                        onClick={() => {
                            openImageViewer(imageURL);
                        }}
                    />
                </div>
                {idx % 2 === 1 && <div className="w-100"></div>}
            </>
        );
    };

    return (
        <>
            {updated_ReviewList.length > 0 ? (
                <Carousel
                    variant="dark"
                    controls={false}
                    className="carousel-indicators-margin">
                    {updated_ReviewList.map((section, idx) => (
                        <Carousel.Item key={idx}>
                            <div className="row justify-content-center">
                                {section.map((review) => (
                                    <div
                                        className="col-lg-3 mb-3 carousel-mobile-card"
                                        key={review.id}
                                    >
                                        <div
                                            className="text-start card-review"
                                            style={{
                                                backgroundColor: pageStyle.cardBgColor ? pageStyle.cardBgColor : '',
                                                color: pageStyle.cardTxtColor ? pageStyle.cardTxtColor : '',
                                                borderRadius: pageStyle.cardBorderRadius ? pageStyle.cardBorderRadius + "px" : "",
                                                borderColor: pageStyle.cardBorderColor ? pageStyle.cardBorderColor : '',
                                                boxShadow: pageStyle.cardShadow ? pageStyle.cardShadow + ` ${pageStyle.cardShadowColor ? pageStyle.cardShadowColor : ''}` : '',
                                            }}
                                        >
                                            <div className="d-flex justify-content-between p-2 px-3 dark-inner-card">
                                                <div className="d-flex flex-row align-items-center">
                                                    {["", null, undefined].includes(
                                                        review.revProfileImg
                                                    ) ? (
                                                        <div className="profile-default-Image pt-2 pb-2">
                                                            <p>{review.revName.charAt(0)}</p>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={review.revProfileImg}
                                                            width="50px"
                                                            height="50px"
                                                            alt=""
                                                            className="rounded-circle"
                                                        />
                                                    )}
                                                    <div className="d-flex flex-column ms-2 review-header-column">
                                                        {review.revWebsite ? (
                                                            <a
                                                                className="font-weight-bold lh-sm review-name set-text-white underline-on-hover"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={review.revWebsite}
                                                            >
                                                                {review.revName}
                                                            </a>
                                                        ) : (
                                                            <span className="font-weight-bold lh-sm review-name set-text-white">
                                                                {review.revName}
                                                            </span>
                                                        )}
                                                        <small className="lh-sm review-name set-text-light-white">
                                                            {review.revTitleCom}
                                                        </small>
                                                        <small className="text-muted lh-sm set-text-light-white">
                                                            {new Date(
                                                                review.createdAt
                                                            ).toLocaleDateString("en-UK", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "numeric",
                                                            })}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-self-center">
                                                    {review.revSocialProvider ===
                                                        "Twitter" && (
                                                            <i className="bi bi-twitter"></i>
                                                        )}
                                                    {review.revSocialProvider ===
                                                        "Google Map" && (
                                                            <i className="bi bi-map"></i>
                                                        )}
                                                </div>
                                            </div>

                                            {review.revContent !== "P undefined" &&
                                                review.revContent !== "P " && (
                                                    <div className="pt-2 pb-2 px-3 dark-inner-card">
                                                        <div className="lh-sm text-justify set-text-white">
                                                            {review.revContent}
                                                        </div>
                                                    </div>
                                                )}

                                            {review.revType === "image" &&
                                                review.revAsset && (
                                                    <div className="row g-0">
                                                        {review.revAsset
                                                            .split(", ")
                                                            .map((imageURL, idx) => (
                                                                <ReviewImageComponent
                                                                    imageURL={imageURL}
                                                                    idx={idx}
                                                                    key={idx}
                                                                />
                                                            ))}
                                                    </div>
                                                )}

                                            {review.revType === "video" && (
                                                // <ReactPlayer url={review.revAsset} controls={true} width="100%" height="200px" className="set-video-dark"/>
                                                <video
                                                    src={review.revAsset}
                                                    controls={true}
                                                    width="100%"
                                                    height="100%"
                                                    className={
                                                        review.revAsset.includes("demo.mp4")
                                                            ? "set-video-dark"
                                                            : "set-video-dark"
                                                    }
                                                ></video>
                                            )}

                                            {review.revType === "audio" && (
                                                <audio
                                                    src={review.revAsset}
                                                    controls
                                                    style={{ width: "100%" }}
                                                    className="review-audio-container"
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            ) : (
                <div className="set-text-white">
                    Reviews Are Not Available
                </div>
            )}

            {isViewerOpen && (
                <ImageViewer
                    src={[imgToDisplay]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </>
    )
}

export default CarouselReviewDisplay