import { useEffect } from "react";
import { useState } from "react";
import { Recorder } from "react-voice-recorder";
//import 'react-voice-recorder/dist/index.css';
import '../audioStyle.css'

const TakeAudioV2 = ({ setRecordedAudio, audioRef }) => {
    const [audioDetails, setAudioDetails] = useState({
        url: null, blob: null, chunks: null, duration: {
            h: 0, m: 0, s: 0
        }
    })

    useEffect(() => {
        function clearBtnListener() {
            const clearBtn = document.getElementsByClassName('_1Pz2d _2gd2_')[0];
            clearBtn.addEventListener('click', (e) => {
                e.preventDefault()
            })

            return () => {
                clearBtn.removeEventListener('click', (e) => {
                    e.preventDefault()
                })
            }
        }

        clearBtnListener()
    }, [])

    const handleAudioStop = (data) => {
        setAudioDetails(data)
        setRecordedAudio(data)
    }

    const handleAudioUpload = (file) => {

    }

    const handleCountDown = (data) => {
        // stop recording if time hits 3 minutes
        if (data.m === 3) {
            const stopBtn = document.querySelector('._1bSom')
            if (stopBtn) {
                stopBtn.click()
            }
        }
    }

    const handleReset = () => {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: 0,
                m: 0,
                s: 0
            }
        };
        setAudioDetails(reset);
        setRecordedAudio(null)
    }

    return (
        <Recorder
            record={true}
            // title={null}
            ref={audioRef}
            hideHeader
            audioURL={audioDetails.url}
            showUIAudio
            handleAudioStop={data => handleAudioStop(data)}
            handleAudioUpload={data => handleAudioUpload(data)}
            handleCountDown={data => handleCountDown(data)}
            handleReset={() => handleReset()}
            // mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
        />
    )
}

export default TakeAudioV2;