import { React, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import NotfoundComponent from "../UI/notfoundComponent";

function Widget2() {
  const [stopSpinner, setStopSpinner] = useState(false);

  const location = useLocation();
  const brandURL = location.pathname.split("/")[2];

  const [reviewList, SetReviewsList] = useState([]);
  const [brandList, SetBrandList] = useState([]);
  const [pageDesign, setPageDesign] = useState({});

  // const url = "https://64kbo1ldn2.execute-api.ap-southeast-1.amazonaws.com/default/v1customerReviewGET/c/" + brandURL + "/" + widgetType

  const url =
    "https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/" +
    brandURL +
    "/widget2";

  const fetchReviews = () => {
    axios
      .get(url)
      .then((res) => {
        if (JSON.parse(res.data.brandDetails).Items.length > 0) {
          SetBrandList(JSON.parse(res.data.brandDetails).Items[0]);
          if (JSON.parse(res.data.brandDetails).Items[0].widget2Design) {
            setPageDesign(
              JSON.parse(res.data.brandDetails).Items[0].widget2Design
            );

            const design = JSON.parse(res.data.brandDetails).Items[0]
              .widget2Design;
            if (design.bgColor && design.useTransparent !== true) {
              document.body.style.backgroundColor = design.bgColor;
            }
          }
        } else {
          SetBrandList({});
        }

        if (res.data.reviews) {
          if (JSON.parse(res.data.reviews).length > 0) {
            SetReviewsList(JSON.parse(res.data.reviews));
          } else {
            SetReviewsList([]);
          }
        }

        setTimeout(() => {
          setStopSpinner(true);
        }, 50);
      })
      .catch(() => {
        console.log("Error loading Data");
      });
  };

  useEffect(() => {
    fetchReviews();

    ReactGA.send({ hitType: "pageview", page: location.pathname });
    //eslint-disable-next-line
  }, []);

  /* Styles */

  const profileDefaultImage = {
    width: "80px",
    height: "80px",
    fontSize: "30px",
    lineHeight: "80px",
  };

  const profileImgSize = {
    height: "80px",
    width: "80px",
  };

  const widgetTheme = {
    backgroundColor: pageDesign.theme === "dark" ? "#0A0A0A" : "#FFFFFF",
    color: pageDesign.theme === "dark" ? "#FFFFFF" : "#000000",
  };

  const textColor = {
    textColor: pageDesign.theme === "dark" ? "#FFFFFF" : "#000000",
  };

  const alignCenter = {
    display: "flex",
    justifyContent: "center",
  };


  /* End of Styles */

  // if (reviewList.length > 2) {
  //   reviewList.splice(1, 1)
  // }

  return (
    <div>
      {stopSpinner === false ? (
        <div className="p-4 text-center">
          <Spinner animation="border" variant="dark" />
        </div>
      ) : (
        <div>
          {Object.keys(brandList).length !== 0 ? (
            <div className="container py-5">
              <Helmet>
                <meta charSet="utf-8" />
                <title>A Nice Review of {brandList.bndName} 2</title>
                <meta
                  name="description"
                  content={"Customers love " + brandList.bndName}
                ></meta>
              </Helmet>
              {reviewList.length > 0 ? (
                <div className="row text-center" style={alignCenter}>
                  {reviewList.map((review) => (
                    <div
                      className="col-md-4 mb-4 py-4"
                      key={review.id}
                      style={widgetTheme}
                    >
                      <div className="mb-1" style={alignCenter}>
                        {/* <img src={review.revProfileImg} width="150px" height="150px" alt="" className="rounded-circle"/> */}
                        {["", null, undefined].includes(
                          review.revProfileImg
                        ) ? (
                          <div
                            className="profile-default-Image"
                            style={profileDefaultImage}
                          >
                            <p>{review.revName.charAt(0)}</p>
                          </div>
                        ) : (
                          <img
                            src={review.revProfileImg}
                            alt=""
                            className="rounded-circle"
                            style={profileImgSize}
                          />
                        )}
                      </div>

                      {review.revWebsite ? (
                        <h5>
                          <a
                            className="mb-1 underline-on-hover"
                            style={textColor}
                            target="_blank"
                            rel="noreferrer"
                            href={review.revWebsite}
                          >
                            {review.revName}
                          </a>
                        </h5>
                      ) : (
                        <h5 className="mb-1" style={textColor}>
                          {review.revName}
                        </h5>
                      )}
                      <h6 className="mb-2">{review.revTitleCom}</h6>
                      {/* <h6 className="text-muted lh-sm">{new Date(review.createdAt).toLocaleDateString('en-UK', { year: 'numeric', month: 'long', day: 'numeric' })}</h6> */}
                      <div className="px-xl-3">
                        {/* {(review.revContent !== "P undefined" && review.revContent !== "P ") &&
                          <div className="pt-2 pb-2 px-3 dark-inner-card">
                            <div className="lh-sm text-justify set-text-white">"{review.revContent}"</div>
                          </div>
                        } */}
                        {review.revContent && (
                          <div className="pt-2 pb-2 px-3 dark-inner-card">
                            <div className="lh-sm text-justify set-text-white">
                              "{review.revContent}"
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center set-text-white">
                  Reviews Are Not Available For This Brand
                </div>
              )}

              {pageDesign?.hideBranding !== true && (
                <div className="ms-auto p-1 text-center">
                  <Link
                    to="https://www.anicereview.com"
                    target="_blank"
                    className="title-header-link text-dark"
                  >
                    Powered By A Nice Review 💪
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <NotfoundComponent brandURL={brandURL} />
          )}
        </div>
      )}
    </div>
  );
}

export default Widget2;
