import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, ToastContainer } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FadeIn } from "react-slide-fade-in";
import NotfoundComponent from "../UI/notfoundComponent";
import CarouselReviewDisplay from "../UI/carouselReviewDisplay";
import WallReviewDisplay from "../UI/wallReviewDisplay";
import Spinner from "react-bootstrap/Spinner"

const CustomWidgetPage = () => {
    const location = useLocation();
    var brandURL = null;
    var widgetID = null;

    if (location.pathname.split('/').length === 4) {
        brandURL = location.pathname.split("/")[2];
        widgetID = location.pathname.split("/")[3].split("widget-")[1]
    }

    const url = `https://9yhxyb4fw2.execute-api.ap-southeast-1.amazonaws.com/default/anrv2customwidgetget-dev/c/${brandURL}/${widgetID}`

    const [brandDetails, setBrandDetails] = useState({})
    const [headerContent, setHeaderContent] = useState({})
    const [pageStyle, setPageStyle] = useState({})
    const [reviewList, setReviewList] = useState([])

    // to make sure that page only loads after all information is retrieved
    const [stopSpinner, setStopSpinner] = useState(false);

    useEffect(() => {
        async function getWidgetContent() {
            await axios.post(url)
                .then((res) => {
                    if (res.data.success) {
                        if (res.data.success) {
                            const data = res.data.success

                            if (data.brandDetails) {
                                setBrandDetails(JSON.parse(data.brandDetails));
                            }
                            if (data.headerContent) {
                                setHeaderContent(JSON.parse(data.headerContent));
                            }
                            if (data.pageStyle) {
                                setPageStyle(JSON.parse(data.pageStyle))
                            }
                            if (data.reviews) {
                                setReviewList(JSON.parse(data.reviews))
                            }
                        }
                    }

                    setTimeout(() => {
                        setStopSpinner(true);
                    }, 50);
                }).catch(() => {
                    console.log("Error retrieving widget details")
                })
        }

        if (brandURL && widgetID) {
            getWidgetContent()
        }
        // eslint-disable-next-line
    }, [])

    const getHeaderBgImg = () => {
        if (headerContent.useDefaultBgImg === true) {
            return `url(https://www.anicereview.co/a-nice-review-background-image.jpg)`
        } else {
            if (headerContent.headerBgImg) {
                return `url(${headerContent.headerBgImg})`
            }
        }
        return ''
    }

    return (
        <div style={{
            backgroundColor: pageStyle.contentBgColor ? pageStyle.contentBgColor : ''
        }}>
            {stopSpinner === false ? (
                <div className="p-5 text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            ) : (
                <>
                    {Object.keys(pageStyle).length > 0 && Object.keys(headerContent).length > 0 ? (
                        <div>
                            <ToastContainer />
                            {headerContent?.showHeader === true && (
                                <FadeIn
                                    from="bottom"
                                    positionOffset={0}
                                    triggerOffset={0}
                                    delayInMilliseconds={50}
                                >
                                    <div className="hero-image" style={{
                                        backgroundColor: pageStyle?.headerBgColor,
                                        backgroundImage: getHeaderBgImg()
                                    }}>
                                        <div className="hero-text">
                                            <div style={{
                                                color: pageStyle?.bndNameTxtColor
                                            }}>
                                                {brandDetails?.bndName}
                                            </div>

                                            <div className="mt-2 mb-4">
                                                {headerContent?.bndLogo && (
                                                    <img
                                                        src={headerContent.bndLogo}
                                                        alt={headerContent?.title}
                                                        style={{ height: "70px" }}
                                                    />
                                                )}
                                            </div>

                                            <div
                                                className="title-header mb-2"
                                                style={{
                                                    color: pageStyle?.bndTitleColor
                                                        ? pageStyle.bndTitleColor
                                                        : "",
                                                }}
                                            >
                                                {headerContent?.title
                                                    ? headerContent.title
                                                    : "Our Reviews"}
                                            </div>

                                            {/* {headerContent.message && (
                                                <div
                                                    className="text-center mb-4"
                                                    style={{
                                                        color: pageStyle.bndMsgColor
                                                            ? pageStyle.bndMsgColor
                                                            : "",
                                                    }}
                                                >
                                                    {headerContent.message}
                                                </div>
                                            )} */}

                                            {headerContent.message && (
                                                <div
                                                    className="text-center mb-4"
                                                    style={{
                                                        color: pageStyle.bndMsgColor
                                                            ? pageStyle.bndMsgColor
                                                            : "",
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: headerContent.message }} />
                                            )}

                                            <div className="ms-auto p-1" id="review-dropdown-container">
                                                <Button
                                                    // as={ButtonGroup}
                                                    className={`title-header-button`}
                                                    // title={headerContent.shareBtn ? headerContent.shareBtn : "Share A Nice Review"}
                                                    // id="bg-nested-dropdown"
                                                    style={{
                                                        backgroundColor: pageStyle.btnBgColor ? pageStyle.btnBgColor : "",
                                                        color: pageStyle.btnTxtColor ? pageStyle.btnTxtColor : ""
                                                    }}
                                                    size="lg" onClick={() => {
                                                        if (headerContent.CTA) {
                                                            window.location.href = headerContent.CTA
                                                        }
                                                    }}>
                                                    {headerContent.shareBtn ? headerContent.shareBtn : "Share A Nice Review"}
                                                </Button>
                                            </div>

                                            {headerContent.isPoweredBy !== "true" && (
                                                <div className="ms-auto">
                                                    <a
                                                        href="https://www.anicereview.com"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="title-header-link"
                                                    >
                                                        Collect And Share Reviews For Free 🤝
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </FadeIn>
                            )}

                            <FadeIn
                                from="bottom"
                                positionOffset={0}
                                triggerOffset={0}
                                delayInMilliseconds={50}
                            >
                                <div
                                    // className={
                                    //     "main-review-page-padding " +
                                    //     (pageStyle.revTheme === "dark"
                                    //         ? "dark-theme-body"
                                    //         : "white-theme-body")
                                    // }
                                    className={
                                        "main-review-page-padding "
                                    }
                                >
                                    {pageStyle.displayType === "wall" && (
                                        <WallReviewDisplay reviewList={reviewList} pageStyle={pageStyle} />
                                    )}
                                    {pageStyle.displayType === "rowsCarousel" && (
                                        <CarouselReviewDisplay reviewList={reviewList} pageStyle={pageStyle} />
                                    )}

                                    {/* {pageStyle.displayType === "column" && (
                                        <>
                                            
                                        </>
                                    )} */}
                                </div>
                            </FadeIn>
                        </div>
                    ) : (
                        <FadeIn
                            from="bottom"
                            positionOffset={0}
                            triggerOffset={0}
                            delayInMilliseconds={50}>
                            <NotfoundComponent brandURL={brandURL} />
                        </FadeIn>
                    )}
                </>
            )}
        </div>
    )
}

export default CustomWidgetPage