import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import CardModal from "../UI/cardModal";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import NotfoundComponent from "../UI/notfoundComponent";

function Widget2a() {
  const [stopSpinner, setStopSpinner] = useState(false);

  const location = useLocation();
  const brandURL = location.pathname.split("/")[2];

  const [reviewList, SetReviewsList] = useState([]);
  const [brandList, SetBrandList] = useState([]);
  const [pageDesign, setPageDesign] = useState({});

  // const url = "https://64kbo1ldn2.execute-api.ap-southeast-1.amazonaws.com/default/v1customerReviewGET/c/" + brandURL + "/" + widgetType

  const url =
    "https://wzjtzj4oud.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewsget/c/" +
    brandURL +
    "/widget2";

  const fetchReviews = () => {
    axios
      .get(url)
      .then((res) => {
        if (JSON.parse(res.data.brandDetails).Items.length > 0) {
          SetBrandList(JSON.parse(res.data.brandDetails).Items[0]);
          if (JSON.parse(res.data.brandDetails).Items[0].widget2Design) {
            setPageDesign(
              JSON.parse(res.data.brandDetails).Items[0].widget2Design
            );

            const design = JSON.parse(res.data.brandDetails).Items[0]
              .widget2Design;
            if (design.bgColor && design.useTransparent !== true) {
              document.body.style.backgroundColor = design.bgColor;
            }
          }
        } else {
          SetBrandList({});
        }

        if (res.data.reviews) {
          if (JSON.parse(res.data.reviews).length > 0) {
            SetReviewsList(JSON.parse(res.data.reviews));
          } else {
            SetReviewsList([]);
          }
        }

        setTimeout(() => {
          setStopSpinner(true);
        }, 50);
      })
      .catch((err) => {
        console.log("Error loading data");
      });
  };

  useEffect(() => {
    fetchReviews();

    ReactGA.send({ hitType: "pageview", page: location.pathname });
    //eslint-disable-next-line
  }, []);

  /* Styles */

  const profileDefaultImage = {
    width: "80px",
    height: "80px",
    fontSize: "30px",
    lineHeight: "80px",
  };

  const profileImgSize = {
    height: "80px",
    width: "80px",
  };

  const widgetTheme = {
    backgroundColor: pageDesign.theme === "dark" ? "#000000" : "#FFFFFF",
    color: pageDesign.theme === "dark" ? "#FFFFFF" : "#000000",
  };

  const textColor = {
    textColor: pageDesign.theme === "dark" ? "#FFFFFF" : "#000000",
  };

  const alignCenter = {
    display: "flex",
    justifyContent: "center",
  };

  // const cardStyle = {
  //   width: "100%",
  //   height: "100%",
  //   padding: "1.5rem"
  // };

  // const subTitle = {
  //   textOpacity: 1,
  //   color: "#6c757d",
  // };

  /* End of Styles */


  // testing num review.
  // if (reviewList.length > 2) {
  //   reviewList.splice(0, 2)
  // }

  return (
    <div>
      {stopSpinner === false ? (
        <div className="p-4 text-center">
          <Spinner animation="border" variant="dark" />
        </div>
      ) : (
        <div>
          {Object.keys(brandList).length !== 0 ? (
            <div className="container py-5">
              <Helmet>
                <meta charSet="utf-8" />
                <title>A Nice Review of {brandList.bndName} 2a</title>
                <meta
                  name="description"
                  content={"People love " + brandList.bndName}
                ></meta>
              </Helmet>
              {reviewList.length > 0 ? (
                <div className="row" style={alignCenter}>
                  {reviewList.map((review) => (
                    <div className="col-md-4 mb-4 py-4" key={review.id}>
                      <CardModal
                        widgetTheme={widgetTheme}
                        profileDefaultImage={profileDefaultImage}
                        profileImgSize={profileImgSize}
                        textColor={textColor}
                        revProfileImg={review.revProfileImg}
                        revName={review.revName}
                        revWebsite={review.revWebsite}
                        revTitleCom={review.revTitleCom}
                        revContent={review.revContent}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center set-text-white">
                  Reviews Are Not Available For This Brand
                </div>
              )}
            </div>
          ) : (
            <NotfoundComponent brandURL={brandURL} />
          )}
        </div>
      )}
    </div>
  );
}

export default Widget2a;
