export function getBrandName(brand) {
    return brand.split("ORG#")[1];
}

export function convertQueryToObject(query) {
    const queryList = query ? query.split("?") : []
    var obj = {};

    if (queryList.length > 0) {
        var splitQuery = queryList[1].split("&");

        splitQuery.map((query) => {
            const qObj = query.split("=");
            obj[qObj[0]] = qObj[1]
        })
    }

    return obj
}

// export function customToastCloseBtn({ closeToast }) {
//     return (
//         <i
//             className="btn btn-primary" style={{ verticalAlign: 'middle' }}
//             onClick={closeToast}
//         >
//             Ok
//         </i>
//     )
// }

export function customToastCloseBtn({ closeToast }) {
    return (
        <button
            className="btn btn-primary" style={{ verticalAlign: 'middle' }}
            onClick={closeToast}
        >
            Ok
        </button>
    )
}

export function ReviewShowMoreBtn() {
    return (
        <i className="bi bi-caret-down"></i>
    )
}

export function ReviewShowLessBtn() {
    return (
        <i className="bi bi-caret-up"></i>
    )
}