import React, { useEffect, useState, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import FormModal from "./formModal";
import axios from "axios";

// import { useReactMediaRecorder } from "react-media-recorder";
import TakeAudio from "./takeAudioV2";
import {
  customToastCloseBtn,
  ReviewShowLessBtn,
  ReviewShowMoreBtn,
} from "../hooks/commonFunctions";

function AudioReviewForm({
  showModal,
  handleClose,
  title,
  editModeActivated,
  setIsUpdatingData,
  reviewToEdit,
  brandURL,
}) {
  // Record Audio
  const hiddenAudioRef = useRef();
  // const [audioPreview, setAudioPreview] = useState(false);
  // const [startAudio, setStartAudio] = useState(true);
  // const [stopAudio, setStopAudio] = useState(false);
  const [chooseAudioBtn, setChooseAudioBtn] = useState(false);

  const [showMore, setShowMore] = useState(false);

  //Make Button Disbaled After Submit
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    control,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const email = useWatch({
    control,
    name: "email",
  });
  const mobile = useWatch({
    control,
    name: "mobile",
  });

  const defaultValues = {
    content: "",
    revAsset: "",
    name: "",
    company: "",
    website: "",
    email: "",
    profileImg: null,
    profileImgType: "",
    profileImgName: "",
    consent: false,
    revFeatured: false,
    revStatus: "draft",
    revType: "",
    source: "",
    mobile: "",
    assetName: "",
    assetType: "",
  };

  useEffect(() => {
    if (editModeActivated) {
      if (reviewToEdit.revType === "audio") {
        reset(reviewToEdit);
      }
    }
    // eslint-disable-next-line
  }, [editModeActivated]);

  //Set Profile Image Upload Preview
  const [profileFile, setProfileFile] = useState();
  const [currentProfileList, setCurrentProfileList] = useState([]);
  const hiddenProfileImage = React.useRef(null);
  const [profileSizeStyle, setProfileSizeStyle] = useState(false);

  //Show Audio File Preview
  const [audioFile, setAudioFile] = useState();
  const [audioStyle, setAudioStyle] = useState(false);
  const [audioSizeStyle, setAudioSizeStyle] = useState(false);
  const [audioCurrentFile, setAudioCurrentFile] = useState([]);

  const [recordedAudio, setRecordedAudio] = useState(null);
  const audioRef = useRef(null);

  const handleFileBtnClick = (event) => {
    hiddenProfileImage.current.click();
  };

  function handleProfileFileChange(e) {
    let fileSize = e.target.files[0].size;
    // 50MB = 1024 * 1024 * 50
    if (e.target.files.length !== 0) {
      if (fileSize < 52428800) {
        setProfileFile(URL.createObjectURL(e.target.files[0]));

        setCurrentProfileList(e.target.files[0]);
        setProfileSizeStyle(false);
      } else {
        setProfileSizeStyle(true);
      }
    }
  }

  function handleAudioFileChange(e) {
    let fileSize = e.target.files[0].size;
    // 50MB = 1024 * 1024 * 50 (52428800)
    // 3000kb = 1024 * 3000 (3072000)
    // 3mb = 1024 * 1024 * 3 (3145728)
    if (e.target.files.length !== 0) {
      if (fileSize < 3145728) {
        setAudioFile(URL.createObjectURL(e.target.files[0]));

        setAudioStyle(true);
        // document.getElementById("audio-empty").style.display = "none"
        // document.getElementById("audio-preview").style.display = "none"
        // setAudioPreview(false);

        setAudioCurrentFile(e.target.files[0]);
        setAudioSizeStyle(false);
      } else {
        setAudioSizeStyle(true);
      }
    }
  }

  const onCreateFormSubmit = async (data) => {
    let audioFileList = "";

    // Create Audio File if user submitted a recorded audio
    if (recordedAudio !== null) {
      const mediaBlob = await fetch(recordedAudio.url).then((response) =>
        response.blob()
      );

      const videoFile = new File([mediaBlob], "demo.mp3", {
        type: "audio/mp3",
      });
      audioFileList = videoFile;
    }

    if (
      audioCurrentFile.name === undefined &&
      audioFileList.name === undefined
    ) {
      toast.error("Please upload a Audio File");
    } else if (!data.email && !data.mobile) {
      toast.error("Please enter email address or mobile number");
    } else if (audioSizeStyle === true) {
      toast.error("Audio File cannot be more than 50MB");
    } else {
      const loadingToast = toast.loading("Sending Review...");

      setDisabled(true);

      const profile_Img = currentProfileList;

      const review_Audio = audioCurrentFile;

      let formAsset;
      let formProfile;

      if (!data.content) {
        data.content = "";
      }

      // Check if there is profile image uploaded
      if (!profile_Img || profile_Img.length === 0) {
        data.profileImgName = "";
        data.profileImgType = "";
      } else {
        data.profileImgName = profile_Img.name;
        data.profileImgType = profile_Img.type;

        formProfile = profile_Img;
      }

      data.revType = "audio";

      // Check whether audio is recorded or choosen from gallery
      if (review_Audio.name !== undefined) {
        //data.revAsset = review_Audio
        data.assetName = review_Audio.name;
        data.assetType = review_Audio.type;
      } else if (audioFileList.name !== undefined) {
        //data.revAsset = audioFileList
        data.assetName = audioFileList.name;
        data.assetType = audioFileList.type;
      }

      data.brandURL = brandURL;
      // const POST_url = "https://hoq2xn5atk.execute-api.ap-southeast-1.amazonaws.com/default/anrv1reviewpost"
      const POST_url =
        "https://2soykez3pj.execute-api.ap-southeast-1.amazonaws.com/default/anrv2reviewpost";

      await axios
        .post(POST_url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          if (!res.data.message) {
            let imageSignedURL = res.data.assetURL;
            let profileSignedURL = res.data.profileImgURL;

            // Check whether video is recorded or choosen from gallery
            if (review_Audio.name !== undefined) {
              formAsset = review_Audio;
            } else if (audioFileList.name !== undefined) {
              formAsset = audioFileList;
            } else {
              formAsset = data.revAsset;
            }

            await axios
              .put(imageSignedURL, formAsset)
              // .then(function (response) {
              //     console.log(response)
              // })
              .catch(function () {
                console.log("Failed to upload audio");
              });

            if (profile_Img) {
              await axios
                .put(profileSignedURL, formProfile)
                // .then(function (response) {
                //     console.log(response)
                // })
                .catch(function () {
                  console.log("Failed to upload profile image");
                });
            }

            handleClose();

            toast.update(loadingToast, {
              render: "Appreciate your support! Thank you.",
              type: "success",
              isLoading: false,
              autoClose: false,
              closeButton: customToastCloseBtn,
            });

            setShowMore(false);
            setIsUpdatingData(true);
            setTimeout(() => {
              setIsUpdatingData(false);
              reset(defaultValues);

              setProfileFile();
              setCurrentProfileList([]);
              setAudioFile();
              setAudioStyle(false);

              // setAudioPreview(false);
              // setStartAudio(true);
              // setStopAudio(false);
              setChooseAudioBtn(false);

              setDisabled(false);

              // doReloadReviewList(!reloadReviewList);
            }, 2500);
          } else {
            toast.update(loadingToast, {
              render: "Error Sending Review",
              type: "error",
              isLoading: false,
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          // console.log("err", error.response);

          toast.update(loadingToast, {
            render: "Error Sending Review",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        });
    }
  };

  const HandleChooseAudio = (event) => {
    hiddenAudioRef.current.click();
  };

  const handleAudioFileClick = (e) => {
    e.target.value = null;
  };

  // "on-change listener for email, mobile and asset fields"
  useEffect(() => {
    if (
      showModal === true &&
      ["", undefined].includes(email) &&
      ["", undefined].includes(mobile)
    ) {
      setError("eitherRequired", {
        type: "custom",
        message: "Please fill in either email or mobile number",
      });
    } else {
      clearErrors("eitherRequired");
    }
    // eslint-disable-next-line
  }, [email, mobile]);

  useEffect(() => {
    if (showModal && recordedAudio === null && audioFile === undefined) {
      setError("revAsset", {
        type: "custom",
        message: "Please upload a Audio File",
      });
    } else {
      clearErrors("revAsset");
    }
    // eslint-disable-next-line
  }, [recordedAudio, audioFile]);

  return (
    <FormModal
      showModal={showModal}
      handleClose={() => {
        handleClose();
        reset(defaultValues);
        setShowMore(false);

        setProfileFile();
        setCurrentProfileList([]);
        setAudioFile();
        setAudioStyle(false);

        setTimeout(() => {
          // setAudioPreview(false);
          setChooseAudioBtn(false);
        }, 200);
      }}
      title={editModeActivated ? "Update " + title : "Write " + title}
      submitButton={
        <button
          className="btn btn-primary"
          onClick={async (e) => {
            e.preventDefault();

            if (
              ["", undefined].includes(email) &&
              ["", undefined].includes(mobile)
            ) {
              setError("eitherRequired", {
                type: "custom",
                message: "Please fill in either email or mobile number",
              });
            }

            if (recordedAudio === null && audioFile === undefined) {
              setError("revAsset", {
                type: "custom",
                message: "Please upload a Audio File",
              });
            }
            // toast.error("Please upload a Audio File")
            if (!editModeActivated) {
              handleSubmit(onCreateFormSubmit)();
            }
          }}
          disabled={disabled}
        >
          Submit
        </button>
      }
    >
      <form>
        <div className="d-none">
          <input type="text" {...register("assetName")} />
          <input type="text" {...register("assetType")} />

          <input type="text" {...register("profileImgName")} />
          <input type="text" {...register("profileImgType")} />
        </div>

        <div className="text-center">
          <small className="text-muted">Max Recording Time (3 minutes)</small>
        </div>
        <TakeAudio setRecordedAudio={setRecordedAudio} audioRef={audioRef} />

        <div className="mb-3">
          {/* Preview Audio for recording option */}
          <audio
            src={audioFile}
            controls
            width="100%"
            id="ut-record-preview"
            className={
              audioStyle ? "d-block mb-2 review-audio-container" : "d-none"
            }
          />
          {/* preview audio for "Choose Audio" option */}
          {/* <div id="audio-preview" className={audioPreview ? "d-block" : "d-none"}>
                        <audio src={mediaBlobUrl} controls width="100%" className="mb-2 review-audio-container" />
                    </div> */}

          <div className="col-12 text-center my-2">
            <span>&nbsp; OR &nbsp;</span>
          </div>

          <button
            type="button"
            id="choose-audio-btn"
            disabled={chooseAudioBtn}
            className="btn btn-outline-primary upload-btn-mobile col-12"
            onClick={HandleChooseAudio}
          >
            Choose Audio
          </button>

          <input
            type="file"
            name="file"
            accept="audio/*"
            className="form-control set-input-dark d-none"
            id="ut-audio-upload"
            // {...register('revAsset', {
            //     required: {
            //         value: editModeActivated ? (reviewToEdit.revAsset === null) : true,
            //         message: "Please upload an audio"
            //     }
            // })}
            onChange={handleAudioFileChange}
            onClick={handleAudioFileClick}
            ref={hiddenAudioRef}
          />
          {errors.revAsset && (
            <span className="text-danger" id="ut-revAsset-error">
              {errors.revAsset.message}
            </span>
          )}
          {/* {hiddenAudioRef.current === null && <span className='text-danger'>Please choose a file</span>} */}
          <span
            className={audioSizeStyle ? "text-danger d-block" : "d-none"}
            id="ut-revAsset-size-error"
          >
            Audio File cannot be more than 3 MB
          </span>
        </div>

        <div className="mb-3">
          <label htmlFor="revName">
            Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control set-input-dark"
            id="name"
            placeholder="Your name"
            {...register("name", {
              required: true,
            })}
          />
          {errors && errors.name && (
            <span className="text-danger" id="ut-name-error">
              Required field
            </span>
          )}
        </div>
        <div className="d-flex">
          <div className="col-lg-5">
            <label htmlFor="revEmail">
              Email address <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control set-input-dark"
              id="revEmail"
              aria-describedby="emailHelp"
              autoFocus
              {...register("email", {
                pattern: {
                  value:
                    /^([a-zA-Z0-9_\\-\\.]{1,})@([a-zA-Z0-9_\\-\\.]{1,}).([a-zA-Z]{2,5})$/,
                  message: "Please enter correct format",
                },
              })}
            />
            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
            {errors && errors.email && (
              <span className="text-danger" id="ut-revEmail-error">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="wrapper">
            <div className="line"></div>
            <div className="wordwrapper">
              <span className="word set-input-dark">or</span>
            </div>
          </div>

          <div className="col-lg-5">
            <label htmlFor="revMobileNo">
              Mobile Number <span className="text-danger">*</span>{" "}
            </label>
            <input
              type="text"
              className="form-control set-input-dark"
              id="mobile"
              {...register("mobile", {
                // required: { value: true, message: "Field is required" },
                pattern: {
                  value: /^([0-9]{1,}[0-9]{5,})$/,
                  message: "Please enter correct format (E.g. 6591234567)",
                },
              })}
            />
            {errors && errors.mobile && (
              <span className="text-danger" id="ut-mobile-error">
                {errors.mobile.message}
              </span>
            )}
          </div>
        </div>

        {errors && errors.eitherRequired && (
          <div className="text-center mt-0 mb-2">
            <span className="text-danger" id="ut-eitherRequired-error">
              {errors.eitherRequired.message}
            </span>
          </div>
        )}

        {!showMore || (
          <div className="my-3">
            <label>Content: </label>
            <textarea
              name="content"
              rows="3"
              className="form-control set-input-dark"
              placeholder="Content"
              maxLength={2048}
              {...register("content")}
            ></textarea>
            {errors && errors.content && (
              <span className="text-danger">{errors.content.message}</span>
            )}
          </div>
        )}

        {!showMore || (
          <div className="mb-3">
            <label htmlFor="company">Review Title: </label>
            <input
              type="text"
              className="form-control set-input-dark"
              id="company"
              placeholder="Review Title"
              {...register("company")}
            />
            {errors && errors.company && (
              <span className="text-danger">Required Field</span>
            )}
          </div>
        )}

        {!showMore || (
          <div className="mb-3">
            <label htmlFor="revWebsite">Website: </label>
            <input
              placeholder="Website"
              type="url"
              className="form-control set-input-dark"
              {...register("website", {
                pattern: {
                  value:
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Please enter correct format",
                },
              })}
            />
            {errors && errors.website && (
              <span className="text-danger">{errors.website.message}</span>
            )}
          </div>
        )}

        {!showMore || (
          <div className="mb-3">
            <label htmlFor="profileImg">Profile Picture: </label>
            <div className="p-2">
              <img
                src={profileFile}
                width="50px"
                height="50px"
                alt=""
                className="rounded-circle shadow-sm"
              />

              <span
                name="profileInput"
                className="btn btn-primary mx-3"
                onClick={handleFileBtnClick}
              >
                Upload{" "}
              </span>
            </div>

            <input
              type="file"
              name="image"
              accept="image/*"
              className="form-control set-input-dark d-none"
              {...register("profileImg")}
              ref={hiddenProfileImage}
              onChange={handleProfileFileChange}
            />
            <span
              className={profileSizeStyle ? "text-danger d-block" : "d-none"}
            >
              Profile Image File cannot be more than 50MB
            </span>
          </div>
        )}

        <div className="mt-3">
          <label htmlFor="consent">
            Consent To Publish Review <span className="text-danger">*</span>
          </label>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                id="cbConsent"
                name="cbConsent"
                {...register("consent", { required: true })}
                className="form-check-input"
              />
              Yes
            </label>
          </div>
          {errors.consent && (
            <span className="text-danger d-block" id="ut-cbConsent-error">
              Consent is required
            </span>
          )}
        </div>

        <div className="pt-2">
          <span id="more-options" onClick={() => setShowMore(!showMore)}>
            {showMore ? <ReviewShowLessBtn /> : <ReviewShowMoreBtn />}{" "}
          </span>
        </div>
      </form>
    </FormModal>
  );
}

export default AudioReviewForm;
