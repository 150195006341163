import { React } from "react";
import { Card } from "react-bootstrap";


function CardModal({ widgetTheme, profileDefaultImage, profileImgSize, textColor, revProfileImg, revName, revWebsite, revTitleCom, revContent }) {

    /* Styles */

    const cardStyle = {
        width: "100%",
        height: "100%",
        padding: "1.5rem"
    };

    const subTitle = {
        textOpacity: 1,
        color: "#6c757d",
    };

    /* End of Styles */

    return (<Card style={{ ...cardStyle, ...widgetTheme }}>
        <Card.Body>
            {["", null, undefined].includes(
                revProfileImg
            ) ? (
                <div
                    className="profile-default-Image"
                    style={profileDefaultImage}
                >
                    <p>{revName.charAt(0)}</p>
                </div>
            ) : (
                <Card.Img
                    variant="top"
                    src={revProfileImg}
                    className="rounded-circle"
                    style={profileImgSize}
                />
            )}
            <Card.Title className="mt-4">
                {revWebsite ? (
                    <h5>
                        <a
                            className="mb-1 underline-on-hover"
                            style={textColor}
                            target="_blank"
                            rel="noreferrer"
                            href={revWebsite}
                        >
                            {revName}
                        </a>
                    </h5>
                ) : (
                    <h5 className="mb-1" style={textColor}>
                        {revName}
                    </h5>
                )}
            </Card.Title>
            <Card.Subtitle className="mb-2" style={subTitle}>
                {revTitleCom}
            </Card.Subtitle>
            <Card.Text>{revContent}</Card.Text>
        </Card.Body>
    </Card>
    );
}

export default CardModal;
