import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertQueryToObject,
  ReviewShowLessBtn,
  ReviewShowMoreBtn,
} from "../../hooks/commonFunctions";
import FormModal from "../formModal";
import ImagePicker from "../custom-forms/media-picker/imagePicker";
import AudioPicker from "../custom-forms/media-picker/audioPicker";
import VideoPicker from "../custom-forms/media-picker/videoPicker";
import { toast } from "react-toastify";

const WriteReview2 = ({
  showModal,
  handleClose,
  title,
  setIsUpdatingData,
  brandURL,
  formFields,
  others,
  formID,
  owner,
  formToShow,
  mediaIsRequired,
  setMediaIsRequired,
  pageDesign,
}) => {
  let navigate = useNavigate();

  // example database variables
  // const pageDesign = {
  //     "formBgColor": "#000000",
  //     "formTxtColor": "#000000",
  //     "mediaBgColor": "#FFFFFF",
  //     "mediaBtnRadius": parseInt("5"),
  //     "mediaTxtColor": "#0d6efd",
  //     "submitBgColor": "#0d6efd",
  //     "submitBtnRadius": parseInt("5"),
  //     "submitTxtColor": "#FFFFFF",
  // }

  const pageStyle = {
    backgroundColor: pageDesign?.writeReviewPage?.formBgColor,
    color: pageDesign?.writeReviewPage?.formTxtColor,
    borderRadius: pageDesign?.writeReviewPage?.formBorderRadius
      ? pageDesign.writeReviewPage.formBorderRadius + "px"
      : "",
    borderColor: pageDesign?.writeReviewPage?.formBorderColor,
    borderWidth: "2px",
    borderStyle: "solid",
  };
  const closeBtnStyle = {
    closeIconColor: pageDesign?.writeReviewPage?.closeIconColor
      ? pageDesign.writeReviewPage.closeIconColor
      : null,
  };

  const submitBtnStyle = {
    backgroundColor: pageDesign?.writeReviewPage?.submitBgColor,
    color: pageDesign?.writeReviewPage?.submitTxtColor,
    borderRadius: pageDesign?.writeReviewPage?.submitBtnRadius
      ? pageDesign?.writeReviewPage?.submitBtnRadius + "px"
      : "",
    borderColor: pageDesign?.writeReviewPage?.submitBtnRadius,
  };

  const mediaUploadBtnStyle = {
    backgroundColor: pageDesign?.writeReviewPage?.mediaBgColor,
    color: pageDesign?.writeReviewPage?.mediaTxtColor,
    borderRadius: pageDesign?.writeReviewPage?.mediaBtnRadius
      ? pageDesign.writeReviewPage.mediaBtnRadius + "px"
      : "",
    borderColor: pageDesign?.writeReviewPage?.mediaBgColor,
  };

  const location = useLocation();
  const queries = location.search;
  var queryObj = convertQueryToObject(queries);

  const [fieldContainer, setFieldContainer] = useState({});
  const [mediaToUpload, setMediaToUpload] = useState(null);
  const [mediaError, setMediaError] = useState(null);

  //Make Button Disbaled After Submit
  // const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    var mandatoryFields = [];
    var optionalFields = [];
    const cusFields = formFields?.customfields ? formFields.customfields : [];
    const defFields = formFields?.defaultfields ? formFields.defaultfields : [];
    const joinedArr = [...defFields, ...cusFields];

    joinedArr.map((field) => {
      if (formToShow === "text" && field.fieldname === "content") {
        mandatoryFields.push(field)
      } else {
        if (field.mandatory === true || field.isMandatory === true) {
          mandatoryFields.push(field);
        } else {
          optionalFields.push(field);
        }
      }
      return "";
    });

    setFieldContainer({ mandatory: mandatoryFields, optional: optionalFields });
  }, [formFields, formToShow]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const [showMore, setShowMore] = useState(false);
  const profileRef = useRef();
  const [profileImg, setProfileImg] = useState();
  const [profilePreview, setProfilePreview] = useState();

  const onSubmit = async (data) => {
    const cusFields = formFields?.customfields ? formFields.customfields : [];

    const customCreated = [];
    // check which fields are custom created
    if (cusFields && cusFields.length > 0) {
      cusFields.map((fieldObj) =>
        customCreated.push({
          [fieldObj.fieldname]: {
            value: data?.[fieldObj.fieldname],
            required: fieldObj.mandatory,
          },
        })
      );
    }

    if (Object.keys(data).length > 0) {
      if (mediaError) {
        toast.error(mediaError);
      } else if (mediaIsRequired && !mediaToUpload) {
        toast.error("Please upload a file");
      } else {
        const loadingToast = toast.loading("Sending Review...");

        data.profileImgName = "";
        data.profileImgType = "";
        data.owner = owner;

        let formAsset;
        let formProfile;

        // Check if there is profile image uploaded
        if (!profileImg || profileImg.length === 0) {
          data.profileImgName = "";
          data.profileImgType = "";
        } else {
          data.profileImgName = profileImg.name;
          data.profileImgType = profileImg.type;

          formProfile = profileImg;
        }

        if (!data.content) {
          data.content = "";
        }

        if (customCreated.length > 0) {
          data.customFields = JSON.stringify(customCreated);
        }

        if (mediaToUpload && mediaToUpload.name !== undefined) {
          if (formToShow === "image") {
            data.revType = "image";
          } else if (formToShow === "video") {
            data.revType = "video";
          } else if (formToShow === "audio") {
            data.revType = "audio";
          }
          data.assetName = mediaToUpload.name;
          data.assetType = mediaToUpload.type;
        } else {
          data.revType = "text";
        }

        data.brandURL = brandURL;

        // upload data to API
        await axios
          .post(
            `https://csg40vuote.execute-api.ap-southeast-1.amazonaws.com/default/anrv2customreviewpost-dev`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (res) => {
            let profileSignedURL = res.data.profileImgURL;

            if (profileImg) {
              await axios
                .put(profileSignedURL, formProfile)
                .catch(function (error) {
                  console.log("Error uploading profile image");
                });
            }

            if (mediaToUpload && mediaToUpload.name !== undefined) {
              formAsset = mediaToUpload;
            } else {
              formAsset = data.revAsset;
            }

            let mediaSignedURL = res.data.assetURL;

            if (mediaSignedURL) {
              await axios.put(mediaSignedURL, formAsset).catch(() => { });
            }

            if (profileSignedURL) {
              await axios.put(profileSignedURL, formProfile).catch(() => { });
            }

            handleClose();

            // toast.update(loadingToast, {
            //     render: "Successfully Created Review",
            //     type: 'success', isLoading: false, autoClose: false, pauseOnFocusLoss: false,
            //     pauseOnHover: false, closeButton: true
            // })

            setShowMore(false);
            setIsUpdatingData(false);

            reset();
            setProfileImg(null);
            setMediaToUpload(null);

            // check if form is using custom thankyou page
            if (
              others.formID &&
              others?.formData?.others?.useCustomThankYou === true
            ) {
              // if using custom thank you, redirect to custom URL
              const customLink = others?.formData?.thankYou?.customURL;
              if (customLink) {
                toast.dismiss(loadingToast);
                window.location.href = customLink;
              }
            } else {
              // if not using custom thank you, redirect user to thank you page
              toast.dismiss(loadingToast);
              navigate(`/brand/${brandURL}/${others.formID}/thankyou`, {
                state: {
                  pageStyle: pageDesign,
                  pageContent: others?.formData?.thankYou,
                },
              });
            }

            // setTimeout(() => {
            //     setIsUpdatingData(false);
            //     reset()
            //     setProfileImg(null);
            //     setMediaToUpload(null);

            //     if (others.formID) {
            //         navigate(`/brand/${brandURL}/${others.formID}/thankyou`, {
            //             state: {
            //                 pageStyle: pageDesign,
            //                 pageContent: others?.formData?.thankYou
            //             }
            //         })
            //     }
            // }, 2500);
          })
          .catch((error) => {
            toast.update(loadingToast, {
              render: "Error Sending Review",
              type: "error",
              isLoading: false,
              autoClose: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              closeButton: true,
            });
          });
      }
    }
  };

  useEffect(() => {
    if (profileImg) {
      const file = profileImg;
      // 50MB = 1024 * 1024 * 50 (52428800)
      // 3000kb = 1024 * 3000 (3072000)
      // 3mb = 1024 * 1024 * 3 (3145728)
      if (
        file &&
        file.type.substring(0, 5) === "image" &&
        file.size <= 3145728
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfilePreview(reader.result);
        };
        reader.readAsDataURL(profileImg);
        clearErrors("profileimg");
      } else {
        if (file.size > 3145728) {
          setError("profileimg", {
            type: "custom",
            message: "File size cannot be more than 50mb",
          });
          setProfilePreview(null);
        }
      }
    } else {
      clearErrors("profileimg");
      setProfilePreview(null);
    }
    // eslint-disable-next-line
  }, [profileImg]);

  const getSize = () => {
    const size = queryObj.size;
    if (size) {
      if (size === "mobile") {
        return "col-sm-6";
      }
    }

    return "";
  };

  useEffect(() => {
    if (formToShow) {
      if (mediaIsRequired === true && !mediaToUpload) {
        setMediaError("Please provide or record a file");
      } else {
        setMediaError(null);
      }
    }
    // eslint-disable-next-line
  }, [mediaToUpload]);

  return (
    <div className={`container ${getSize()}`}>
      <FormModal
        showModal={showModal}
        handleClose={() => {
          handleClose();
          reset();
          setShowMore(false);

          setMediaToUpload(null);
          profileRef.current = null;
          setMediaError(null);
        }}
        title={title}
        pageStyle={pageStyle}
        closeBtnStyle={closeBtnStyle}
        submitButton={
          <button
            className="btn"
            style={submitBtnStyle}
            onClick={() => {
              if (mediaIsRequired === true && !mediaToUpload) {
                setMediaError(`Please upload or record a file`);
              }

              handleSubmit(onSubmit)();
            }}
          >
            {others?.formData?.welcome?.submitBtn
              ? others.formData.welcome.submitBtn
              : "Save"}
          </button>
        }
      >
        <form>
          {formToShow === "image" && (
            <ImagePicker
              setMediaToUpload={setMediaToUpload}
              mediaError={mediaError}
              mediaUploadBtnStyle={mediaUploadBtnStyle}
            />
          )}

          {formToShow === "audio" && (
            <AudioPicker
              setMediaToUpload={setMediaToUpload}
              mediaError={mediaError}
              mediaUploadBtnStyle={mediaUploadBtnStyle}
            />
          )}

          {formToShow === "video" && (
            <VideoPicker
              setMediaToUpload={setMediaToUpload}
              mediaError={mediaError}
              mediaUploadBtnStyle={mediaUploadBtnStyle}
            />
          )}

          {(fieldContainer?.mandatory ? fieldContainer.mandatory : []).map(
            (field, idx) => (
              <div className="mb-3" key={`mandatory-${idx}`}>
                {/* Fieldname or label */}
                <label>
                  {field.label ? field.label : field.fieldname}{" "}
                  <span className="text-danger">*</span>{" "}
                </label>
                {field.fieldtype === "textbox" && (
                  <input
                    className="form-control"
                    {...register(field.fieldname, {
                      required: {
                        value: true.valueOf,
                        message: `${field.label} is required`,
                      },
                    })}
                  />
                )}
                {field.fieldtype === "textarea" && (
                  <textarea
                    name={field.fieldname}
                    rows="3"
                    className="form-control set-input-dark"
                    id={`ut-${field.fieldname}`}
                    {...register(field.fieldname, { required: true })}
                  ></textarea>
                )}
                {field.fieldtype === "dropdown" && (
                  <select
                    type="text"
                    className="custom form-select"
                    {...register(field.fieldname, { required: true })}
                  >
                    <option value=""></option>
                    {field.options &&
                      field.options.split(/[,\s]+/).map((option, idx) => (
                        <option value={option} key={idx}>
                          {option}
                        </option>
                      ))}
                  </select>
                )}
                {errors && errors[field.fieldname] && (
                  <span
                    className="text-danger"
                    id={`ut-${field.fieldname}-error`}
                  >
                    {errors[field.fieldname].message}
                  </span>
                )}
              </div>
            )
          )}

          <div className="mt-3">
            <label htmlFor="consent">
              {others.consentMessage
                ? others.consentMessage
                : "Consent To Publish Review"}{" "}
              <span className="text-danger">*</span>{" "}
            </label>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  id="cbConsent"
                  name="cbConsent"
                  {...register("consent", { required: true })}
                  className="form-check-input"
                />
                Yes
              </label>
            </div>
            {errors.consent && (
              <span className="text-danger d-block" id="ut-cbConsent-error">
                Consent is required
              </span>
            )}
          </div>

          {!showMore ||
            (fieldContainer?.optional ? fieldContainer.optional : []).map(
              (field, idx) => (
                <div className="my-3" key={`optional-${idx}`}>
                  <label>{field.label ? field.label : field.fieldname}</label>
                  {field.fieldtype === "textbox" && (
                    <input
                      className="form-control"
                      {...register(field.fieldname)}
                    />
                  )}
                  {field.fieldtype === "textarea" && (
                    <>
                      {field.fieldname === "content" ? (
                        <textarea
                          name={field.fieldname}
                          rows="3"
                          className="form-control set-input-dark"
                          id={`ut-${field.fieldname}`}
                          maxLength={2048}
                          {...register(field.fieldname)}
                        ></textarea>
                      ) : (
                        <textarea
                          name={field.fieldname}
                          rows="3"
                          className="form-control set-input-dark"
                          id={`ut-${field.fieldname}`}
                          {...register(field.fieldname)}
                        ></textarea>
                      )}
                    </>
                  )}

                  {field.fieldtype === "file" &&
                    field.fieldname === "profileimg" && (
                      <div>
                        {profilePreview ? (
                          <>
                            <img
                              src={profilePreview}
                              alt="preview"
                              style={{
                                objectFit: "cover",
                                width: "125px",
                                height: "125px",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                profileRef.current.click();
                              }}
                            />
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              ref={profileRef}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (
                                  file &&
                                  file.type.substring(0, 5) === "image"
                                ) {
                                  setProfileImg(file);
                                } else {
                                  setProfileImg(null);
                                }
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                background: "#fef289",
                                width: "125px",
                                height: "125px",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                profileRef.current.click();
                              }}
                            >
                              Add Image
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              ref={profileRef}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (
                                  file &&
                                  file.type.substring(0, 5) === "image"
                                ) {
                                  setProfileImg(file);
                                } else {
                                  setProfileImg(null);
                                }
                              }}
                            />
                          </>
                        )}
                      </div>
                    )}

                  {field.fieldtype === "dropdown" && (
                    <select
                      type="text"
                      className="custom form-select"
                      {...register(field.fieldname)}
                    >
                      <option value=""></option>
                      {field.options &&
                        field.options.split(/[,\s]+/).map((option, idx) => (
                          <option value={option} key={idx}>
                            {option}
                          </option>
                        ))}
                    </select>
                  )}

                  {errors && errors[field.fieldname] && (
                    <span
                      className="text-danger"
                      id={`ut-${field.fieldname}-error`}
                    >
                      {errors[field.fieldname].message}
                    </span>
                  )}
                </div>
              )
            )}

          <div className="pt-2">
            <span id="more-options" onClick={() => setShowMore(!showMore)}>
              {showMore ? <ReviewShowLessBtn /> : <ReviewShowMoreBtn />}{" "}
            </span>
          </div>
        </form>
      </FormModal>
    </div>
  );
};

export default WriteReview2;
